<template>
  <v-chart
    v-if="ready"
    :forceFit="true"
    :scale="scale"
    :height="canvasHeight"
    :data="dataSource"
    :padding="padding">
    <v-tooltip />
    <v-legend />
    <v-axis :dataKey="yAxisName" :title="{ text: yAxisTitle }" :label="yAxisLabel" />
    <v-axis :dataKey="xAxisName" :label="label"/>
    <template v-if="facetField">
      <v-facet type="list" :cols="facetCol" :fields="[facetField]" :padding="30">
        <v-facet-view>
          <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
          <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
          <v-bar v-else :position="xAxisName + '*' + yAxisName" />
        </v-facet-view>
      </v-facet>
    </template>
    <template v-else>
      <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
      <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
      <v-bar v-else :position="xAxisName + '*' + yAxisName" />
    </template>
  </v-chart>
</template>
<script>
// https://viserjs.gitee.io/demo.html
import { simplifyNumber } from '@/utils/numberUtil'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import _, { debounce } from 'lodash'
const DataSet = require('@antv/data-set')
/**
 * 只支持：
 * 1个指标，或者1~2个维度
 * 1+个指标相同单位，1~2个维度
 */
export default {
  name: 'FsColumnChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    seriesType: {
      type: String,
      default: 'Group'
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      ready: false,
      canvasHeight: 300,
      dataSource: [],
      xAxisName: '',
      yAxisName: '',
      yAxisTitle: '',
      seriesName: '',
      xAxisLen: 0,
      seriesLen: 0,
      facetCol: 2,
      facetField: '',
      facetLen: 0,
      xAxisTags: [],
      morespace: 40,
      adjust: [{
        type: 'dodge',
        marginRatio: 1 / 32
      }]
    }
  },
  computed: {
    yAxisLabel() {
      return {
        formatter(val) {
          return simplifyNumber(val)
        }
      }
    },
    scale () {
      const len = this.xAxisTags.length
      return [{
        dataKey: this.xAxisName,
        type: 'cat',
        tickCount: len > 20 ? 20 : len
      }]
    },
    label () {
      const len = this.xAxisTags.length
      return {
        autoRotate: false,
        rotate: len > 5 ? len > 10 ? 40 : len * 2 : 0,
        offset: 15,
        textStyle: {
          textAlign: len > 5 ? 'start' : 'center'
        }
      }
    },
    padding() {
      return [40, 100, 150, 100]
    }
  },
  mounted() {
    const _unit = this.reportData.units[0]['unit']
    const _unitEnclosed = _unit ? `（${_unit}）` : ''
    this.xAxisName = this.xAxis || this.reportData['dimensions'][0]
    if (this.reportData['metrics'].length > 1) {
      // 如果指标数量大于1，用指标分类成系列（color）
      const dv = new DataSet.View().source(this.reportData.dataItems)
      dv.transform({
        type: 'fold',
        fields: this.reportData['metrics'],
        key: 'metricsName',
        value: 'metricsValue'
      })
      this.seriesName = 'metricsName'
      this.yAxisName = 'metricsValue'
      this.yAxisTitle = this.reportData['metrics'].join('、') + _unitEnclosed
      this.dataSource = dv.rows
      if (this.reportData['dimensions'].length === 2) {
        this.facetField = this.reportData['dimensions'].find(item => item !== this.xAxisName)
      }
    } else {
      this.yAxisName = this.reportData['metrics'][0]
      this.yAxisTitle = this.reportData['metrics'][0] + _unitEnclosed
      this.dataSource = this.reportData['dataItems']
      if (this.reportData['dimensions'].length === 2) {
        this.seriesName = this.reportData['dimensions'].find(item => item !== this.xAxisName)
      }
    }
    this.xAxisLen = _.uniqBy(this.dataSource, this.xAxisName).length
    this.seriesLen = _.uniqBy(this.dataSource, this.seriesName).length
    if (this.facetField) {
      this.facetLen = _.uniqBy(this.dataSource, this.facetField).length
      if (this.xAxisLen > 8) this.facetCol = 1
      const _height = Math.ceil(this.facetLen / this.facetCol) * 400
      this.canvasHeight = _height > this.height ? _height : this.height
    } else {
      this.canvasHeight = this.height
    }
    this.ready = true
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    this.morespace = this.reportData.series.length < 20 ? 45 : (this.reportData.series.length < 40 ? 105 : (this.reportData.series.length < 60 ? 155 : 200))
    this.chartRenderedDelay()
    this.getPaddingBottom()
    this.getPaddingRight()
  },
  methods: {
    getPaddingBottom () {
      let maxLength = 0
      this.xAxisTags.forEach((item) => {
        if (getStrFullLength(item) > maxLength) {
          maxLength = getStrFullLength(item)
        }
      })
      this.padding[2] = 14 + maxLength / 2 * 8 + this.morespace // 每个字8
    },
    getPaddingRight () {
      const len = this.xAxisTags.length
      let i = len - 2
      let tag = 1
      let maxLength = getStrFullLength(this.xAxisTags[len - 1])
      while (i >= 0) {
        // 8表示当前一个比后一个多4个字以上时，才会导致前一个标签为到右边最远的标签
        // 将离右边最近的标签与其之前的标签对比，得到往右延伸更远的标签
        if (maxLength + 8 * tag < getStrFullLength(this.xAxisTags[i])) {
          maxLength = getStrFullLength(this.xAxisTags[i])
          tag = 1
        }
        i--
        tag++
      }
      this.padding[1] = 3 + maxLength / 2 * 9 + 10
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
</style>
