<template>
  <div class="dashboard-image" :class="imageClass" :style="imageStyle" />
</template>

<script>
import PropertyHelp from '../data/PropertyHelp'
export default {
  name: 'DashboardImage',
  components: {
  },
  props: {
    model: {
      type: Object,
      default () {
        return {
          src: ''
        }
      }
    },
    dashboardModel: {
      type: Object,
      default: () => {
        return {
          dataRegionId: ''
        }
      }
    },
    activeIndex: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
    }
  },
  computed: {
    imageClass () {
      const { backgroundImage } = this.model
      return { placeholder: !backgroundImage }
    },
    imageStyle () {
      // const backgroundStyle = PropertyHelp.getBackgroundStyle(this.model)
      // const borderStyle = PropertyHelp.getBorderStyle(this.model)
      const paddingStyle = PropertyHelp.getPaddingStyle(this.model)
      return Object.assign({}, paddingStyle)
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>
