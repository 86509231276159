import _ from 'lodash'

export function getFontStyle(model) {
  return {
    fontSize: `${model['fontSize']}px`,
    height: `${model['height']}px`,
    lineHeight: `${model['lineHeight']}px`,
    color: model['color'],
    textAlign: model['textAlign'],
    fontWeight: model['fontWeight']
  }
}

export function getBackgroundStyle(model) {
  const style = {}
  if (model['backgroundColor']) style.backgroundColor = model['backgroundColor']
  if (model['backgroundImage']) {
    style.backgroundImage = `url(${model['backgroundImage']})`
    if (model['backgroundPositionX']) style.backgroundPositionX = model['backgroundPositionX']
    if (model['backgroundPositionY']) style.backgroundPositionY = model['backgroundPositionY']
    if (model['backgroundSize']) style.backgroundSize = model['backgroundSize']
    if (model['backgroundRepeat']) style.backgroundRepeat = model['backgroundRepeat']
  }
  return style
}

export function getBorderStyle(model) {
  // let showBorder = false
  // if (model['borderImage']) showBorder = true
  // if (Math.max(...model['borderWidth'].split(' ')) > 0) showBorder = true
  const borders = _.trim(model['borderWidth']).split(/\s+/)
  const style = {}
  if (borders.length) style.borderWidth = borders.map(i => `${i}px`).join(' ')
  if (model['borderRadius']) style.borderRadius = `${model['borderRadius']}px`
  if (model['borderColor']) style.borderColor = model['borderColor']
  if (model['borderStyle']) style.borderStyle = model['borderStyle']
  if (model['borderImage']) style.borderImage = `url(${model['borderImage']}) ${borders.join(' ')} fill stretch`
  return style
}

export function getPaddingStyle(model) {
  const paddings = _.trim(model.padding).split(/\s+/)
  const padding = paddings.map(p => `${p}px`).join(' ')
  return { padding }
}

export function getAlignClass(model) {
  return {
    left: model['horizontal'] === 'left',
    center: model['horizontal'] === 'center',
    right: model['horizontal'] === 'right',
    top: model['vertical'] === 'top',
    middle: model['vertical'] === 'middle',
    bottom: model['vertical'] === 'bottom'
  }
}

export default {
  getFontStyle,
  getBackgroundStyle,
  getBorderStyle,
  getPaddingStyle,
  getAlignClass
}
