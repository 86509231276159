<template>
  <div class="history-chart-list card">
    <div v-if="title" class="card-title">
      {{ title }}
    </div>
    <div class="card-content">
      <div v-if="dataList.length" style="position: relative; height: 100%">
        <ChartItem
          ref="refChartItem"
          v-for="(item, index) in dataList"
          :key="item['id']"
          :history-data="item"
          :style="itemStyle(item, index)"
          :class="{'key-value-box1': item['chartType']==='KeyValue' && item['onHalf'] !== 'end',
                   'key-value-box2': item['chartType']==='KeyValue' && item['onHalf'] === 'end'}"
          :theme="getChartTheme(item)"
          @remove="onRemove(index)"/>
      </div>
      <van-empty v-else image="search" description="暂无收藏" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ChartItem from '@/components/homePage/ChartItem'
import { ATTACHMENT_PREFIX } from '@/config'
import { GenerateTheme } from '@/theme/chart/theme'

export default {
  name: 'ChartList',
  components: {
    ChartItem
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataLoading: false,
      dataList: [],
      originData: {},
      total: 10
    }
  },
  computed: {
    ...mapState({
      dataRegion: state => state.dataRegion.activeItem,
      selectHistories: state => state.speech.selectHistories
    })
  },
  methods: {
    loadData () {
      this.dataLoading = true
      const params = {
        dataRegionId: this.dataRegion.id,
        thumbnail: true,
        page: 0,
        size: this.total
      }
      this.$api.speech.searchTrackList('headlines', params).then(res => {
        const totalElements = Number(res.totalElements)
        if (totalElements > this.total) {
          this.total = totalElements
          this.loadData()
        } else {
          this.originData = res
          this.formatData()
          this.dataLoading = false
        }
      }).catch((error) => {
        this.dataLoading = false
        console.log(error)
      })
    },
    formatData () {
      const { content } = this.originData
      let defaultIndex = 0
      content.forEach((item, index) => {
        if (['KeyValue', 'Ring'].indexOf(item['chartType']) === -1) {
          item['chartThemeIndex'] = defaultIndex
          if (defaultIndex !== 2) defaultIndex++ // 三种循环
          else defaultIndex = 0
        }
        item['h'] = item['chartType'] === 'KeyValue' ? 108 : item['chartType'] === 'Ring' ? 180 : 231
        if (item['y'] === undefined) item['y'] = index === 0 ? 0 : content[index - 1]['y'] + content[index - 1]['h'] + 6
        if (item['words']) item['searchWords'] = item['words']
        if (item['thumbnail']) item['thumbnail'] = ATTACHMENT_PREFIX + item['thumbnail']
        if (['KeyValue', 'Ring'].indexOf(item['chartType']) !== -1 && index !== content.length - 1 && !item['onHalf']) {
          // 键值图、环形图，不是最后一个，没有配对
          if (content[index + 1]['chartType'] === item['chartType']) { // 和下一个图表类型相同，两个各显示屏幕一半
            item['onHalf'] = 'start'
            content[index + 1]['onHalf'] = 'end'
            content[index + 1]['y'] = item['y']
          }
        }
      })
      this.dataList = content
    },
    startSelect () {
      this.$store.dispatch('speech/setSelectHistories', true)
    },
    cancelSelect () {
      this.$store.dispatch('speech/setSelectHistories', false)
    },
    onRemove (index) {
      // this.dataList.splice(index, 1)
      this.loadData()
    },
    itemStyle (item, index) {
      let width = 'calc(100% - 30px)'
      // const height = 243
      let left = 0
      const top = index === 0 ? 0 : item['y']
      // console.log('top', top)
      const padding = [6, 15, 0, 15]
      if (item['onHalf'] === 'start') {
        width = 'calc(50% - 16.5px)'
        padding[1] = 1.5
      } else if (item['onHalf'] === 'end') {
        left = '50%'
        width = 'calc(50% - 16.5px)'
        padding[3] = 1.5
      }
      if (index === this.dataList.length - 1) padding[2] = 6
      return {
        position: 'absolute',
        width: `${width}`,
        height: `${item['h']}px`,
        left: `${left}`,
        top: `${top}px`,
        padding: `${padding.join('px ')}px`
      }
    },
    getChartTheme (item) {
      let chartTheme = {}
      const colorsObj = {
        'Ring': [
          ['#308be3', '#1ac4aa', '#ffa151', '#eb5352'],
          ['#ff715e', '#ffaf51', '#ffee51', '#797fba', '#715c87']],
        'default': [
          ['#ff715e', '#ffaf51', '#ffee51', '#797fba', '#715c87'],
          ['#2ec7c9', '#b6a2de', '#5ab1ef', '#ffb980', '#d87a80', '#8d98b3', '#e5cf0d', '#97b552', '#95706d', '#dc69aa', '#07a2a4', '#9a7fd1', '#588dd5', '#f5994e', '#c05050', '#59678c', '#c9ab00', '#7eb00a', '#6f5553', '#c14089'],
          ['#308be3', '#1ac4aa', '#ffa151', '#eb5352']
        ]
      }
      if (item['chartType'] === 'Ring') {
        if (item['onHalf'] === 'end') { // 第二个环形图
          chartTheme = GenerateTheme({
            colors: colorsObj['Ring'][1],
            textColor: '',
            fontSize: 10
          })
        } else { // 第一个环形图
          chartTheme = GenerateTheme({
            colors: colorsObj['Ring'][0],
            textColor: '',
            fontSize: 10
          })
        }
      } else if (item['chartType'] !== 'KeyValue') {
        chartTheme = GenerateTheme({
          colors: colorsObj['default'][item['chartThemeIndex']],
          textColor: '',
          fontSize: 10
        })
      }
      return chartTheme
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    dataRegion(val) {
      this.loadData()
    }
  }
}
</script>
<style lang="less">
.history-chart-list {
  height: 100%;
  overflow: auto;
  .card-content {
    height: 100%;
    background: transparent;
  }
}
</style>
