<template>
  <div class="dashboard-filter" :class="contentClass">
    <div class="dashboard-filter-inner" :style="contentStyle">
      <a-popover overlay-class-name="dashboard-filter-popover" placement="bottomLeft" :trigger="['click']">
        <template slot="title">
          <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>
        </template>
        <template slot="content">
          <perfect-scrollbar class="dashboard-filter-items" style="height:100px">
            <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange"/>
          </perfect-scrollbar>
        </template>
        <span class="dashboard-filter-indicator">{{ selectedValue }}</span>
      </a-popover>
    </div>
  </div>
</template>

<script>
import PropertyHelp from '../data/PropertyHelp'
import _ from 'lodash'
import { KeywordParseInteraction } from '../data/KeywordParseInteraction'

// 二次交互指令
const directives = [
  'directive_add', // 增加
  'directive_remove', // 移除
  'directive_replace', // 替换
  'directive_by_all' // 不限
]
export default {
  name: 'DashboardFilter',
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          content: ''
        }
      }
    },
    dashboardModel: {
      type: Object,
      default: () => {
        return {
          dataRegionId: ''
        }
      }
    }
  },
  data() {
    return {
      checkedList: [],
      plainOptions: [],
      indeterminate: false,
      checkAll: false,
      fieldName: ''
    }
  },
  computed: {
    selectedValue() {
      const label = this.model.content
      const val = this.checkedList.join('，') || '不限'
      return `${label}：${val}`
    },
    contentClass() {
      const alignClass = PropertyHelp.getAlignClass(this.model.contentStyle)
      return Object.assign({ placeholder: !this.model.content }, alignClass)
    },
    contentStyle() {
      const fontStyle = PropertyHelp.getFontStyle(this.model.contentStyle)
      const paddingStyle = PropertyHelp.getPaddingStyle(this.model)
      return Object.assign({}, fontStyle, paddingStyle)
    }
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length
      this.checkAll = checkedList.length === this.plainOptions.length
      this.emitChange()
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked
      })
      this.emitChange()
    },
    getSelectItems() {
      const { content } = this.model
      const { dataRegionId } = this.dashboardModel
      if (!content || !dataRegionId) return
      this.fieldName = _.trim(content)
      this.$api.speech.parseMixKeyword({
        keywords: [this.fieldName],
        dataRegionId,
        printDataRequest: true,
        ignoreHistory: true
      }).then((res) => {
        const { categoryFields } = res['reportDataRequest']
        if (categoryFields.length === 1) {
          const { dataFieldId, dataFieldAlias } = categoryFields[0]
          this.$api.dataset.getFieldValues(dataFieldId).then(res1 => {
            const { data } = res1
            if (data && _.isArray(data)) {
              this.plainOptions.splice(0)
              data.forEach(item => {
                this.plainOptions.push(item[dataFieldAlias])
              })
            }
          })
        }
      })
    },
    emitChange() {
      const keyword = this.checkAll ? `不限${this.fieldName}` : `只看 ${this.checkedList.join(' ')}`
      this.$eventBus.$emit('DASHBOARD_CHART_INTERACTIVE', {
        action: 'DashboardFilterChange',
        params: { keyword }
      })
    },
    preParseKeyword(keyword) {
      this.keyword = keyword
      const dataRegionId = this.dashboardModel.dataRegionId
      this.$api.speech.parseMixKeyword({
        keywords: [ keyword ],
        dataRegionId,
        previousKeywordSearchHistoryId: null,
        printDataRequest: false,
        ignoreHistory: false
      }).then(res => {
        if (res['keywordMatch'].length) {
          const keywordParseInteraction = new KeywordParseInteraction(keyword, res['keywordMatch'])
          this.appendKeyword(keywordParseInteraction)
        }
      })
    },
    appendKeyword(keywordParseInteraction) {
      const { keyword, keywordMatch } = keywordParseInteraction
      console.log(keyword, keywordMatch)
      let directive = ''
      const values = []
      keywordMatch.forEach(keyword => {
        const { keywordCategory, showForm, relationKeyword, keywordOutputDirect } = keyword
        if (keywordCategory === 'SHOW_FORM' && directives.includes(showForm['type'])) {
          directive = showForm['type']
        } else if (keywordCategory === 'DIMENSION_VALUE' && relationKeyword === this.model.content) {
          if (this.plainOptions.includes(keywordOutputDirect)) {
            values.push(keywordOutputDirect)
          }
        }
      })
      const { checkedList } = this
      if (directive === 'directive_add') {
        values.forEach(val => {
          const i = checkedList.indexOf(val)
          if (i === -1) checkedList.push(val)
        })
      } else if (directive === 'directive_remove') {
        values.forEach(val => {
          const i = checkedList.indexOf(val)
          if (i > -1) checkedList.splice(i, 1)
        })
      } else if (directive === 'directive_replace') {
        checkedList.splice(0)
        checkedList.push(...values)
      } else if (directive === 'directive_by_all') {
        checkedList.splice(0)
      }
    },
    chartInteractive(arg) {
      console.log('DashboardFilter.chartInteractive', arg)
      const { action, params } = arg
      if (action === 'AppendKeyword') {
        this.preParseKeyword(params['keyword'])
      }
    }
  },
  mounted() {
    this.getSelectItems()
    this.$eventBus.$on('DASHBOARD_CHART_INTERACTIVE', this.chartInteractive)
  },
  destroyed() {
    this.$eventBus.$off('DASHBOARD_CHART_INTERACTIVE')
  },
  watch: {
    'model.content'() {
      this.getSelectItems()
    }
  }
}
</script>
