<template>
  <div class="component-wrapper" :style="backgroundStyle">
    <div class="component-background" :style="borderStyle"></div>
    <div class="component-inner">
      <ImageItem v-if="model.type === 'image'" :model="model"/>
      <TextItem v-if="model.type === 'text'" :model="model"/>
      <ChartItem v-if="model.type === 'chart'" :model="model" :dashboard-model="dashboardModel" :boxHeight="chartHeight"/>
      <FilterItem v-if="model.type === 'filter'" :model="model" :dashboard-model="dashboardModel"/>
    </div>
  </div>
</template>

<script>
import ChartItem from './chart/ChartNew'
import ImageItem from './image/Image'
import TextItem from './text/Text'
import FilterItem from './filter/Filter'
import PropertyHelp from '@/utils/PropertyHelp'

export default {
  name: 'DashboardChildNew',
  components: {
    ChartItem,
    FilterItem,
    ImageItem,
    TextItem
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    dashboardModel: {
      type: Object,
      default() {
        return {}
      }
    },
    chartHeight: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    backgroundStyle() {
      const backgroundStyle = PropertyHelp.getBackgroundStyle(this.model)
      const borderStyle = PropertyHelp.getBorderStyle(this.model)
      if (!borderStyle['borderImage']) {
        return Object.assign({}, backgroundStyle, borderStyle)
      } else {
        return Object.assign({}, backgroundStyle)
      }
    },
    borderStyle() {
      const borderStyle = PropertyHelp.getBorderStyle(this.model)
      if (borderStyle['borderImage']) {
        return Object.assign({}, borderStyle)
      } else {
        return {}
      }
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>
