<template>
  <div class="component component-chart" :class="wrapperClass">
    <div v-if="!titleHide" class="component-chart-head" :style="titleStyle" :title="chartTitle">{{ chartTitle }}</div>
    <div class="component-chart-body" :style="chartStyle">
      <div ref="refSpeechSmartChart" v-if="ready" style="height: 100%">
        <key-value-chart
          v-if="chartType === 'KeyValue'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          :model="model"
          @chartRendered="onChartRendered" />

        <!-- <pie-chart
          v-if="chartType === 'Pie'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" /> -->

        <ring-chart
          v-if="chartType === 'Ring'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <bar-chart
          v-if="chartType === 'Bar'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <!-- <bar-chart
          v-if="chartType === 'BarGroup'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          seriesType="Group"
          @chartRendered="onChartRendered"
          :theme="chartTheme" /> -->

        <MultiFacetChart
          v-if="chartType === 'FacetColumnTable'"
          chartType="Column"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme"/>

        <SimpleRectChart
          v-if="chartType === 'ColumnGroup'"
          chartType="Column"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :fixHeight="chartHeight-4"
          seriesType="Group"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <SimpleRectChart
          v-if="['Line', 'Column', 'Area', 'LineColumn'].indexOf(chartType) !== -1"
          :chartType="chartType"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :fixHeight="chartHeight-4"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <MultiFacetChart
          v-if="chartType === 'FacetLineTable'"
          chartType="Line"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme"/>

        <MultiFacetChart
          v-if="chartType === 'FacetLineColumnTable'"
          chartType="LineColumn"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <radar-chart
          v-if="chartType === 'Radar'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <tree-chart
          v-if="chartType === 'Radar'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <table-chart-new
          v-if="chartType === 'Table'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          :model="model"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <facet-chart
          v-if="chartType === 'FacetLine'"
          chart-type="Line"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :chartHeight="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <facet-chart
          v-if="chartType === 'FacetColumn'"
          chart-type="Column"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :chartHeight="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <facet-chart
          v-if="chartType === 'FacetBar'"
          chart-type="Bar"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :chartHeight="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <facet-chart
          v-if="chartType === 'FacetArea'"
          chart-type="Area"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :chartHeight="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <facet-chart
          v-if="chartType === 'FacetLineColumn'"
          chart-type="LineColumn"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :chartHeight="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <bar-table-chart
          v-if="chartType === 'BarTable'"
          chart-type="Bar"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :chartHeight="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <tree-diagram-chart
          v-if="chartType === 'Tree'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme"/>

        <bubble-chart
          v-if="chartType === 'Bubble'"
          :reportData="reportChartData"
          :xAxis="adviseXAxis"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <point-chart
          v-if="chartType === 'Point'"
          :reportData="reportChartData"
          :height="chartHeight"
          @chartRendered="onChartRendered"
          :theme="chartTheme" />

        <map-chart
          v-if="chartType === 'Map'"
          :reportData="reportChartData"
          :theme="chartTheme" />

      </div>
      <div v-if="loading" class="component-chart-spinner">
        <van-loading size="24px" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import _, { debounce } from 'lodash'
import KeyValueChart from './KeyValueChart'
// import PieChart from '@/components/charts/PieChart'
import RingChart from '@/components/charts/RingChart'
import BarChart from '@/components/charts/BarChart'
// import LineChart from '@/components/charts/LineChart'
// import LineColumnChart from '@/components/charts/LineColumnChart'
// import ColumnChart from '@/components/charts/ColumnChart'
// import FsColumnChart from '@/components/charts/FsColumnChart'
// import AreaChart from '@/components/charts/AreaChart'
import RadarChart from '@/components/charts/RadarChart'
import FacetChart from '@/components/charts/FacetChart'
import TreeChart from '@/components/charts/TreeChart'
import BubbleChart from '@/components/charts/BubbleChart'
import TableChartNew from '@/components/charts/TableChartNew'
import PointChart from '@/components/charts/PointChart_new'
import BarTableChart from '@/components/charts/BarTableChart'

import TreeDiagramChart from '@/components/charts/TreeDiagramChart'

import SimpleRectChart from '@/components/charts/SimpleRectChart'
import MultiFacetChart from '@/components/charts/MultiFacetChart'
import MapChart from '@/components/charts/MapChart'

import BenchmarkingPopup from '@/components/speech/BenchmarkingPopup'
import PredictPopup from '@/components/speech/PredictPopup'
import EarlyWarningDialog from '@/components/speech/EarlyWarningDialog'
import WxShareTips from '@/components/global/WxShareTips'
import KeywordMatched from '@/utils/keywordMatched'
import ReportChartData from '@/utils/reportData'
// import { getFontStyle } from '@/utils/PropertyHelp'
import PropertyHelp from '../data/PropertyHelp'

import { GenerateTheme } from '@/theme/chart/theme'
export default {
  name: 'ChartNew',
  components: {
    KeyValueChart,
    // PieChart,
    RingChart,
    // LineChart,
    // LineColumnChart,
    // ColumnChart,
    // FsColumnChart,
    // AreaChart,
    RadarChart,
    TreeChart,
    BubbleChart,
    FacetChart,
    BarChart,
    TableChartNew,
    TreeDiagramChart,
    BenchmarkingPopup,
    PredictPopup,
    PointChart,
    WxShareTips,
    BarTableChart,
    SimpleRectChart,
    MultiFacetChart,
    EarlyWarningDialog,
    MapChart
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          content: '',
          titleStyle: {
            interactive: true,
            height: 30,
            hidden: true
          },
          contentStyle: {},
          chartType: ''
        }
      }
    },
    dashboardModel: {
      type: Object,
      default: () => {
        return {
          dataRegionId: ''
        }
      }
    },
    autoChange: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      ready: false,
      keywordMatch: null,
      reportDataRequest: null,
      keywordSearchHistoryId: null,
      keywordMatched: null,
      reportChartData: null,
      adviseXAxis: '',
      chartHeight: 200
    }
  },
  computed: {
    ...mapState({
      activeDataRegionId: state => state.dataRegion.activeItem.id,
      canPredictDateFields: state => state.speech.canPredictDateFields
    }),
    wrapperClass() {
      return {
        placeholder: !this.model['content']
      }
    },
    chartTitle() {
      const _label = this.model.label ? `[${this.model.label}]` : ''
      let _title = this.model.title
      if (!this.model.titleStyle.constant && _.isArray(this.keywordMatch)) {
        _title = this.keywordMatch.map(k => k['matchedValue']).join('')
      }
      return `${_label}${_title || '标题'}`
    },
    titleHide() {
      if (this.model) {
        if (this.model.titleStyle) {
          return this.model.titleStyle.hidden
        }
      }
      return false
    },
    titleStyle() {
      const fontStyle = PropertyHelp.getFontStyle(this.model.titleStyle)
      const backgroundStyle = PropertyHelp.getBackgroundStyle(this.model.titleStyle)
      const paddingStyle = PropertyHelp.getPaddingStyle(this.model.titleStyle)
      return Object.assign({ height: `${this.model.titleStyle.height}px` }, fontStyle, backgroundStyle, paddingStyle)
      // return {}
    },
    chartType() {
      const { chartType, chartTypes } = this.model
      if (chartTypes.length > 0) {
        if (chartTypes.includes(chartType)) {
          return chartType
        } else {
          return chartTypes[0]
        }
      } else {
        return ''
      }
    },
    chartTheme() {
      return GenerateTheme({
        colors: this.model['chartColors'],
        textColor: this.model.contentStyle['color'],
        fontSize: this.model.contentStyle['fontSize']
      })
    },
    chartStyle() {
      const fontStyle = PropertyHelp.getFontStyle(this.model.contentStyle)
      const paddingStyle = PropertyHelp.getPaddingStyle(this.model)
      return Object.assign({ height: this.chartHeight + 'px' }, fontStyle, paddingStyle)
    }
  },
  methods: {
    calChartHeight() {
      const { titleStyle } = this.model
      console.log('this.$el', this.$el)
      if (!this.$el) return
      console.log('onBodyResize', this.$el.clientHeight)
      // const wrapperHeight = this.$el.clientHeight
      const wrapperHeight = this.$el.clientHeight
      console.log('this.$el.clientHeight', this.$el.clientHeight, wrapperHeight)
      this.chartHeight = (titleStyle.hidden ? wrapperHeight : wrapperHeight - titleStyle.height) - 5 // 标题marginBottom: 5
      // return this.boxHeight - 37
    },
    onChartRendered() {
      //
    },
    parseKeyword(keyword, forceInteractive) {
      const { dataRegionId } = this.dashboardModel
      if (_.isEmpty(keyword)) return
      if (_.isEmpty(dataRegionId)) {
        this.$toast.fail('数据域未设置！')
        return
      }
      this.$api.speech.parseMixKeyword({
        keywords: [keyword],
        dataRegionId,
        previousKeywordSearchHistoryId: this.keywordSearchHistoryId,
        printDataRequest: true,
        ignoreHistory: false
      }).then(res => {
        const { keywordMatch, reportDataRequest } = res
        if (res['diyModel']) {
          this.getDiyModelData(res['diyModel'])
        } else if (keywordMatch.length > 0 && !(forceInteractive && !reportDataRequest['secondInteract'])) {
          // 未匹配到不继续
          // 强制二次交互时，解析出不是二次交互不继续
          this.reportDataRequest = reportDataRequest
          this.keywordSearchHistoryId = reportDataRequest.keywordSearchHistoryId
          this.getReportData(reportDataRequest)
        }
        this.keywordMatch = keywordMatch
        this.keywordMatched = new KeywordMatched(keyword, keywordMatch)
        this.adviseXAxis = this.keywordMatched['adviseDatetimeAxis']
      }).catch(() => {
        // this.loading = false
      })
    },
    getReportData(reportDataRequest) {
      this.loading = true
      const { model } = this
      this.$api.speech.getReportData(reportDataRequest).then(res => {
        this.reportData = res[0]
        this.reportChartData = new ReportChartData(res[0])
        model.chartTypes.splice(0)
        if (this.reportDataRequest['bizObjectSearch']) {
          model.chartTypes.push('Table')
        } else {
          model.chartTypes.push(...this.reportChartData.getSmartChartTypes(this.keywordMatched['adviseDatetimeAxis']))
        }
        this.ready = false
        this.readyDelay()
        if (!this.autoChange) model.content = this.chartTitle
      }).catch(error => {
        if (error.response.data.message) this.$toast.fail(error.response.data.message)
        this.emptyData = true
      }).finally(() => {
        this.loading = false
      })
    },
    getDiyModelData(diyModelRequest) {
      this.loading = true
      const { model } = this
      this.$api.speech.getDiyModelData(diyModelRequest).then(res => {
        this.reportChartData = new ReportChartData(res)
        model.chartTypes.splice(0)
        model.chartTypes.push(...this.reportChartData.getSmartChartTypes(this.keywordMatched['adviseDatetimeAxis']))
        this.ready = false
        this.readyDelay()
        if (!this.autoChange) model.content = this.chartTitle
      }).catch(error => {
        if (error.response.data.message) this.$message.error(error.response.data.message)
        this.emptyData = true
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.readyDelay = debounce(() => {
      if (this.reportChartData) this.ready = true
    }, 300)
    this.calChartHeight()
    this.parseKeyword(this.model.content)
  },
  watch: {
  }
}
</script>
<style lang="less">

</style>
