<template>
  <div class="key-value-chart" :style="{height: height + 4 + 'px'}">
    <van-empty v-if="dataSource.length === 0" image-size="80"/>
    <div class="key-value-item" v-for="item in dataSource" :key="item['key']">
      <div class="value">{{ item['value'] }} {{ item['unit'] }}</div>
      <div class="name">{{ item['name'] }}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { simplifyNumber, formatNumberThousands } from '@/utils/numberUtil'
export default {
  name: 'KeyValueChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dataItems () {
      if (this.reportData['dataItems'] && this.reportData['dataItems'].length > 0) {
        return this.reportData['dataItems'][0]
      } else {
        return {}
      }
    },
    dataUnits () {
      return this.reportData['units'] || []
    },
    dataSource () {
      const _result = []
      const _keys = Object.keys(this.dataItems)
      _keys.forEach((_key) => {
        const _val = this.dataItems[_key]
        _result.push({
          key: uuidv4(),
          name: _key,
          value: this.isHistory ? simplifyNumber(_val) : formatNumberThousands(_val),
          unit: this.getUnitByMetrics(_key)
        })
      })
      return _result
    }
  },
  methods: {
    getUnitByMetrics (metrics) {
      const _exist = this.dataUnits.find(item => item['metrics'].indexOf(metrics) > -1)
      return _exist ? _exist['unit'] : ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('chartRendered')
    })
  }
}
</script>
<style lang="less">
  .key-value-chart {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .key-value-item {
      flex: 1;
      width: 100%;
      // background: #fff;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border-top: 1px solid #efefef;

      &:first-child {
        border: none;
      }
    }
    .key-value-item .value {
      font-size: 1.6em;
      color: #000;
    }
    .key-value-item .name {
      font-size: 1.2em;
      color: #666;
    }
  }
  .theme-dark {
    .key-value-item {
      background: none;
      border-top: 1px solid rgba(100, 100, 100, 0.4);
      .value {
        color: #999;
      }
      .name {
        color: #666
      }
    }
  }
</style>
