<template>
  <div class="dataregion-popover">
    <van-popover
      placement="bottom"
      v-model="showPopover"
      trigger="click"
      :actions="popoverActions"
      @select="onSelect"
    >
      <template #reference>
        <div class="title">
          <div class="active-dataRegion-name">{{ activeDataRegion['name'] }}</div>
        </div>
        <!-- <div class="active-dataRegion-name">{{ activeDataRegion['name'] }} <van-icon v-if="dataLen" name="arrow-down" color="#ccc" style="padding-left: 5px;" /></div> -->
      </template>
    </van-popover>
  </div>
</template>
<script>

import { mapState } from 'vuex'

export default {
  name: 'DataRegionPopover',
  components: {
  },
  data() {
    return {
      showPopover: false,
      actions: []
    }
  },
  computed: {
    ...mapState({
      activeDataRegion: state => state.dataRegion.activeItem,
      dataRegionDataList: state => state.dataRegion.dataList
    }),
    popoverActions () {
      return this.dataRegionDataList.map(item => {
        item.text = item.name
        return item
      })
    },
    dataLen () {
      return this.dataRegionDataList.length
    }
  },
  methods: {
    show () {
      this.showPopover = true
    },
    onSelect (val) {
      this.$store.dispatch('dataRegion/setActiveItem', val)
      this.$emit('ACTIVE_DATA_REGION_CHANGE', val)
    }
  },
  mounted() {
    // this.$store.dispatch('dataRegion/getDataList')
  }
}
</script>
<style lang="less">
.dataregion-popover {
  .van-popover__wrapper {
    width: 100%;
    .active-dataRegion-name {
      font-size: 19px;
      font-weight: bold;
      font-family: PingFang SC;
      color: #ffffff;
      margin-top: 3px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
      max-width: 100%;
    }
  }
}
</style>
