<template>
  <div class="speech-smart-chart" ref="speechSmartChartBox" >
    <!-- <van-cell class="speech-smart-chart-header" :title="title"> -->
    <van-cell class="speech-smart-chart-header" :title="isHistory ? keyword.join('|'): '数据'" @click="clickTitle">
      <div slot="extra" class="extra" v-if="!isHistory">
        <template v-if="canPredict">
          <predict-popup
            :xAxis="xAxis"
            ref="refPredictPopup" />
          <!-- <predict-popup
            :xAxis="xAxis"
            @predictData="predictData"
            ref="refPredictPopup" /> -->
        </template>
        <template v-if="canBenchmarking">
          <benchmarking-popup
            ref="refBenchmarkingPopup"
            @appendBenchmarkingData="appendBenchmarkingData"
            :keywordMatch="keywordMatch" />
        </template>
        <template v-if="reportChartData['metrics'].length">
          <EarlyWarningDialog
            :metricsArray="reportChartData['metrics']"
            :keywordString="keywordString"
            :keywordSearchHistoryId="keywordSearchHistoryId"
            ref="refEarlyWarningDialog"/>
        </template>
        <KeywordFilterIcon
          ref="keywordFilterIcon"
          @submit="FilterParseKeyword"
          :keyword="keyword.join()"
          :dataRegionId="activeDataRegionId"
          :keywordMatched="keywordMatched"
          :reportData="reportData"
          :ignoreImplicit="ignoreImplicit"/>
        <van-popover
          v-if="!actionDisabled"
          class="smart-chart-action"
          ref="SmartChartAction"
          v-model="showAction"
          :actions="actionOptions"
          placement="bottom-end"
          @select="onActionSelect"
          trigger="click">
          <template #reference>
            <!-- <van-button size="mini" class="operate-btn">操作 <van-icon name="arrow-down" style="vertical-align: middle;"/></van-button> -->
            <van-button size="mini" class="operate-btn">
              <div class="operate-box">
                <div class="operate-div">
                </div>
                <div class="operate-div">
                </div>
                <div class="operate-div">
                </div>
              </div>
            </van-button>
          </template>
        </van-popover>
      </div>
    </van-cell>
    <div class="speech-smart-chart-content">
      <div ref="refSpeechSmartChart" v-if="ready">
        <key-value-chart
          v-if="chartType === 'KeyValue'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :isHistory="isHistory"
          :height="smartChartHeight"
          @chartRendered="onChartRendered" />

        <pie-chart
          v-if="chartType === 'Pie'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <ring-chart
          v-if="chartType === 'Ring'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <bar-chart
          v-if="chartType === 'Bar'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <bar-chart
          v-if="chartType === 'BarGroup'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          seriesType="Group"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <MultiFacetChart
          v-if="chartType === 'FacetColumnTable'"
          chartType="Column"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme"/>

        <SimpleRectChart
          v-if="chartType === 'ColumnGroup'"
          chartType="Column"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          seriesType="Group"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <SimpleRectChart
          v-if="['Line', 'Column', 'Area', 'LineColumn'].indexOf(chartType) !== -1"
          :chartType="chartType"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <MultiFacetChart
          v-if="chartType === 'FacetLineTable'"
          chartType="Line"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <MultiFacetChart
          v-if="chartType === 'FacetLineColumnTable'"
          chartType="LineColumn"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <radar-chart
          v-if="chartType === 'Radar'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <tree-chart
          v-if="chartType === 'Radar'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <table-chart
          v-if="chartType === 'Table'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <facet-chart
          v-if="chartType === 'FacetLine'"
          chart-type="Line"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :smartChartHeight="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <facet-chart
          v-if="chartType === 'FacetColumn'"
          chart-type="Column"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :smartChartHeight="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <facet-chart
          v-if="chartType === 'FacetBar'"
          chart-type="Bar"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :smartChartHeight="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <facet-chart
          v-if="chartType === 'FacetArea'"
          chart-type="Area"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :smartChartHeight="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <facet-chart
          v-if="chartType === 'FacetLineColumn'"
          chart-type="LineColumn"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :smartChartHeight="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <bar-table-chart
          v-if="chartType === 'BarTable'"
          chart-type="Bar"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :smartChartHeight="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <tree-diagram-chart
          v-if="chartType === 'Tree'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <bubble-chart
          v-if="chartType === 'Bubble'"
          :reportData="reportChartDataPrivate"
          :xAxis="xAxis"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <point-chart
          v-if="chartType === 'Point'"
          :reportData="reportChartDataPrivate"
          :height="smartChartHeight"
          @chartRendered="onChartRendered"
          :theme="theme" />

        <map-chart
          v-if="chartType === 'Map'"
          :reportData="reportChartDataPrivate" />

      </div>
      <van-loading v-if="!ready" size="24px">智能AI正在计算…</van-loading>
    </div>
  </div>
</template>
<script>
// import { debounce } from 'lodash'
import KeywordFilterIcon from '@/components/speech/KeywordFilterIcon'
import KeyValueChart from '@/components/charts/KeyValueChart'
import PieChart from '@/components/charts/PieChart'
import RingChart from '@/components/charts/RingChart'
import BarChart from '@/components/charts/BarChart'
import LineChart from '@/components/charts/LineChart'
import LineColumnChart from '@/components/charts/LineColumnChart'
import ColumnChart from '@/components/charts/ColumnChart'
import FsColumnChart from '@/components/charts/FsColumnChart'
import AreaChart from '@/components/charts/AreaChart'
import RadarChart from '@/components/charts/RadarChart'
import FacetChart from '@/components/charts/FacetChart'
import TreeChart from '@/components/charts/TreeChart'
import BubbleChart from '@/components/charts/BubbleChart'
import TableChart from '@/components/charts/TableChart'
import PointChart from '@/components/charts/PointChart_new'
import BarTableChart from '@/components/charts/BarTableChart'

import TreeDiagramChart from '@/components/charts/TreeDiagramChart'

import SimpleRectChart from '@/components/charts/SimpleRectChart'
import MultiFacetChart from '@/components/charts/MultiFacetChart'
import MapChart from '@/components/charts/MapChart'

// import SingleChart from './SingleChart'
// import MultiMetricsChart from './MultiMetricsChart'
// import MultiDimensionsChart from './MultiDimensionsChart'

import domToImage from 'dom-to-image'
import { mapState } from 'vuex'
import BenchmarkingPopup from '@/components/speech/BenchmarkingPopup'
import PredictPopup from '@/components/speech/PredictPopup'
import EarlyWarningDialog from '@/components/speech/EarlyWarningDialog'
import { isDatetimeDimension } from '@/utils/dimensionUtil'
// import ReportChartData from '@/utils/reportData'
import _ from 'lodash'
import { ATTACHMENT_PREFIX } from '@/config'

// import { downloader } from '@/utils/util'
// const jsonExport = require('jsonexport')

// 湖南省 湖南省 2018年 湖南省 应收电费  地市 月份
export default {
  name: 'SpeechSmartChart',
  components: {
    KeywordFilterIcon,
    KeyValueChart,
    PieChart,
    RingChart,
    LineChart,
    LineColumnChart,
    ColumnChart,
    FsColumnChart,
    AreaChart,
    RadarChart,
    TreeChart,
    BubbleChart,
    FacetChart,
    BarChart,
    // SingleChart,
    // MultiMetricsChart,
    // MultiDimensionsChart,
    TableChart,
    TreeDiagramChart,
    BenchmarkingPopup,
    PredictPopup,
    PointChart,
    BarTableChart,
    SimpleRectChart,
    MultiFacetChart,
    EarlyWarningDialog,
    MapChart
  },
  props: {
    uuid: {
      type: String,
      default: ''
    },
    keyword: {
      type: Array,
      default: () => {
        return []
      }
    },
    reportChartData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    keywordMatch: {
      type: Array,
      default: () => {
        return []
      }
    },
    chartType: {
      type: String,
      default: 'Line'
    },
    xAxis: {
      type: String,
      default: ''
    },
    keywordString: {
      type: String,
      default: ''
    },
    keywordSearchHistoryId: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 300
    },
    isHistory: {
      type: Boolean,
      default: false
    },
    isCollection: {
      type: Boolean,
      default: false
    },
    isHeadlines: {
      type: Boolean,
      default: false
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    },
    keywordMatched: {
      type: Object,
      default() {
        return { }
      }
    },
    reportData: {
      type: Object,
      default() {
        return { }
      }
    },
    ignoreImplicit: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showAction: false,
      btnOptions: [
        { text: '预测', icon: 'chart-trending-o', value: 0, disabled: true },
        { text: '对标', icon: 'award-o', value: 1, disabled: true },
        { text: '通知', icon: 'bulb-o', value: 7, disabled: true },
        { text: '加入常用搜索', icon: 'todo-list-o', value: 2 },
        { text: '移除常用搜索', icon: 'todo-list-o', value: 3 },
        { text: '加入首页显示', icon: 'wap-home-o', value: 4 },
        { text: '移除首页显示', icon: 'wap-home-o', value: 5 },
        { text: '分享', icon: 'share-o', value: 6 }
      ],
      actionOptions: [],
      reportChartDataPrivate: {},
      title: '',
      ready: false,
      canBenchmarking: false,
      canPredict: false,
      thumb: null,
      thumbUrl: '',
      actionSelectValue: null
    }
  },
  computed: {
    ...mapState({
      activeDataRegionId: state => state.dataRegion.activeItem.id,
      canPredictDateFields: state => state.speech.canPredictDateFields,
      deviceType: state => state.global.deviceType
    }),
    smartChartHeight () {
      return Math.floor(this.height - 50)
    },
    actionDisabled () {
      return !this.thumb || !this.keywordString || !this.activeDataRegionId
    }
  },
  methods: {
    FilterParseKeyword (params) {
      this.$emit('FilterParseKeyword', params)
    },
    onActionSelect (action) {
      const { value } = action
      this.actionSelectValue = value
      console.log('this.actionSelectValue', this.actionSelectValue)
      if (value === 0) {
        this.$refs['refPredictPopup'].show()
      } else if (value === 1) {
        this.$refs['refBenchmarkingPopup'].show()
      } else if (value === 2) {
        this.addFrequentSearch()
      } else if (value === 3) {
        this.removeFrequentSearch()
      } else if (value === 4) {
        this.addHomePage()
      } else if (value === 5) {
        if (this.isHistory) {
          this.$emit('removeHomePage')
        } else {
          this.removeHomePage()
        }
      } else if (value === 6) {
        const parentChildren = this.$root.$children[0].$children[0].$children
        const searchIndexPage = parentChildren.find(item => item.$el._prevClass === 'speech-index')
        if (this.deviceType === 'android' || this.deviceType === 'ios') {
          searchIndexPage.$refs['refAndroidShareTips'].show()
        } else {
          if (!this.thumbUrl) { // 微信小程序时
            this.$api.speech.collectKeywords({
              searchWords: this.keywordString,
              dataRegionId: this.activeDataRegionId,
              keywordSearchHistoryId: this.keywordSearchHistoryId,
              chartType: this.chartType,
              thumbnail: this.thumb
            }).then((res) => {
              this.thumbUrl = ATTACHMENT_PREFIX + res['thumbnail']
              searchIndexPage.$refs['refWxShareTips'].share({
                thumbUrl: this.thumbUrl,
                title: this.keywordString
              })
            })
          } else {
            searchIndexPage.$refs['refWxShareTips'].share({
              thumbUrl: this.thumbUrl,
              title: this.keywordString
            })
          }
        }
      } else if (value === 7) {
        this.$refs['refEarlyWarningDialog'].create()
      }
    },
    // 添加到常用搜索
    addFrequentSearch () {
      this.$api.speech.collectKeywords({
        searchWords: this.keywordString,
        dataRegionId: this.activeDataRegionId,
        keywordSearchHistoryId: this.keywordSearchHistoryId,
        chartType: this.chartType,
        thumbnail: this.thumb
      }).then((res) => {
        this.thumbUrl = res['thumbnail']
        this.$toast('已添加到常用搜索')
        this.$eventBus.$emit('FrequentSearchChange')
        this.actionOptions.splice(3, 1, this.btnOptions[4])
      })
    },
    // 添加到主页
    addHomePage () {
      this.$api.speech.collectHeadlines({
        searchWords: this.keywordString,
        dataRegionId: this.activeDataRegionId,
        keywordSearchHistoryId: this.keywordSearchHistoryId,
        chartType: this.chartType,
        thumbnail: this.thumb
      }).then((res) => {
        this.thumbUrl = res['thumbnail']
        this.$toast('已添加到主页')
        this.actionOptions.splice(4, 1, this.btnOptions[6])
      })
    },
    // 删除常用搜索
    removeFrequentSearch () {
      this.$api.speech.cancelCollectKeywords({
        searchWords: this.keywordString,
        dataRegionId: this.activeDataRegionId
      }).then(res => {
        this.$toast('已移除常用搜索')
        this.$eventBus.$emit('FrequentSearchChange')
        this.actionOptions.splice(3, 1, this.btnOptions[3])
      })
    },
    // 从主页移除
    removeHomePage () {
      this.$api.speech.cancelCollectHeadlines({
        searchWords: this.keywordString,
        dataRegionId: this.activeDataRegionId
      }).then(res => {
        this.$toast('已移除首页显示')
        this.actionOptions.splice(4, 1, this.btnOptions[5])
      })
    },
    initOp () {

    },
    appendBenchmarkingData (appendDataArr) {
      this.ready = false
      const _data = _.cloneDeep(this.reportChartDataPrivate)
      _data['dataItems'].forEach(dataItem => {
        appendDataArr.forEach(appendData => {
          appendData['data'].forEach(appendItem => {
            let _append = true
            _data['dimensions'].forEach(dimensions => {
              if (dataItem[dimensions] !== appendItem[dimensions]) _append = false
            })
            if (_append) {
              Object.assign(dataItem, appendItem)
            }
          })
        })
      })
      appendDataArr.forEach(appendData => {
        _data['metrics'].push(appendData['metrics'])
        const _unit = _data['units'].find(_dataItem => _dataItem['unit'] === appendData['unit'])
        if (_unit) {
          _unit['metrics'].push(appendData['metrics'])
        } else {
          _data['units'].push({
            unit: appendData['unit'],
            metrics: [appendData['metrics']]
          })
        }
      })
      this.reportChartDataPrivate = _data
      this.$nextTick(() => {
        this.ready = true
      })
    },
    onChartRendered () {
      domToImage.toPng(this.$refs['speechSmartChartBox'], { quality: 0.8, bgcolor: '#ffffff' }).then(boxThumb => {
        domToImage.toPng(this.$refs['refSpeechSmartChart'], { quality: 0.8, bgcolor: '#ffffff' }).then(thumb => {
          this.thumb = thumb
          this.$emit('thumbCreated', {
            uuid: this.uuid,
            chartType: this.chartType,
            boxThumb: boxThumb,
            thumb
          })
        }).catch(function(error) {
          console.error('1、oops, something went wrong!', error)
        })
      }).catch(function(error) {
        console.error('2、oops, something went wrong!', error)
      })
    },
    // downloadAsImage () {
    //   domToImage.toPng(this.$refs['speechSmartChartBox'], { bgcolor: '#ffffff' })
    //     .then(img => {
    //       // downloader(img, `${this.title}.png`)
    //     })
    // },
    downloadAsExcel () {
      const headers = []
      const rename = []
      // console.log('reportData', this.reportChartData)
      this.reportChartData['columns'].forEach(column => {
        headers.push(column['title'])
        if (column['unit']) {
          rename.push(`${column['title']}（${column['unit']}）`)
        } else {
          rename.push(column['title'])
        }
      })
    },
    clickTitle (e) {
      const refs = this.$refs
      if ([refs['refPredictPopup']?.$el, refs['refBenchmarkingPopup']?.$el, refs['keywordFilterIcon']?.$el.childNodes[0], refs['SmartChartAction']?.$el].indexOf(e.target.parentNode) === -1) {
        this.$emit('ifShowContent')
      }
    }
  },
  mounted () {
    this.actionOptions = _.cloneDeep(this.btnOptions)
    this.reportChartDataPrivate = _.cloneDeep(this.reportChartData)
    if (this.reportChartData['metrics'].length) {
      this.actionOptions[2]['disabled'] = false
    }
    // this.title = this.reportChartDataPrivate['metrics'].join('、')
    this.title = this.keyword.join('|')
    // 预测和对标按钮只在轴是连续时间序列且图表类型是线图、柱状图和混合图时生效
    if (isDatetimeDimension(this.xAxis) && ['Line', 'Column', 'LineColumn'].indexOf(this.chartType) > -1) {
      this.canPredict = true
      this.actionOptions[0]['disabled'] = false
    }
    if (isDatetimeDimension(this.xAxis) && ['Line', 'Column', 'LineColumn'].indexOf(this.chartType) > -1) {
      this.canBenchmarking = true
      this.actionOptions[1]['disabled'] = false
    }
    if (this.isHistory) {
      this.actionOptions.splice(3, 1)
      this.actionOptions.splice(3, 1)
      this.actionOptions.splice(3, 1)
      this.title = this.keywordString
    } else {
      // console.log('isHeadlines', this.isHeadlines)
      // console.log('isCollection', this.isCollection)
      if (this.isHeadlines) {
        this.actionOptions.splice(5, 1)
      } else {
        this.actionOptions.splice(6, 1)
      }
      if (this.isCollection) {
        this.actionOptions.splice(3, 1)
      } else {
        this.actionOptions.splice(4, 1)
      }
    }
    this.ready = true
  },
  watch: {
    isCollection (newVal) {
      if (newVal) {
        this.actionOptions.splice(3, 1, this.btnOptions[4])
      } else {
        this.actionOptions.splice(3, 1, this.btnOptions[3])
      }
    },
    isHeadlines (newVal) {
      if (newVal) {
        this.actionOptions.splice(4, 1, this.btnOptions[6])
      } else {
        this.actionOptions.splice(4, 1, this.btnOptions[5])
      }
    },
    showAction (newVal) {
      if (newVal) {
        window.history.pushState({ title: '图表选项', url: '#' }, '图表选项', '#')
      } else {
        if (history.state.title === '图表选项' && ![0, 1, 6, 7].includes(this.actionSelectValue)) { // 自动关闭但不包括部分要打开新弹窗的
          history.back()
        }
      }
    }
  }
}
</script>

<style lang="less">
  .speech-smart-chart  {
    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-header {
      padding: 10px 12px;
      background: transparent;
      .van-cell__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-size: 15px;
      }
    }
    &-content {
      background: transparent;
      // background: #fff;
      margin: 0 10px;
      // border: 1px solid #E3E5E8;
      // border-radius: 5px;
      .g2-tooltip {
        display: none;
      }
    }
    .extra {
      display: flex;
      justify-content: flex-end;
      width: 4em;
      .smart-chart-action {
        display: flex;
        align-items: center;
        .operate-btn {
          vertical-align: middle;
          font-size: 0.8rem;
          border: none;
          background-color: transparent;
          height: 16px;
          margin-bottom: 5px;
          .operate-box {
          margin-bottom: 3px;
            .operate-div {
              border-bottom: 1px solid #606061;
              height: 3px;
              width: 0.9em;
            }
          }
        }
      }
    }
  }
</style>
