const filterDirectiveTypes = [
  'directive_add',
  'directive_remove',
  'directive_replace',
  'directive_by_all'
]

export class KeywordParseInteraction {
  keyword = ''
  keywordMatch = null
  hasMetrics = false // 含有指标
  hasDatetime = false // 含有日期
  hasDimensionName = false // 含有维度名
  hasDimensionValue = false // 含有维度值
  hasFilterDirective = false // 含有过滤指令: directive_add, directive_remove, directive_replace, directive_by_all

  constructor (keyword, keywordMatch) {
    this.keyword = keyword
    this.keywordMatch = keywordMatch
    keywordMatch.forEach(k => {
      if (k['keywordCategory'] === 'METRICS_NAME') this.hasMetrics = true
      if (k['keywordCategory'] === 'DATETIME') this.hasDatetime = true
      if (k['keywordCategory'] === 'DIMENSION_TIME') this.hasDatetime = true
      if (k['keywordCategory'] === 'DIMENSION_NAME') this.hasDimensionName = true
      if (k['keywordCategory'] === 'DIMENSION_VALUE') this.hasDimensionValue = true
      if (k['showForm'] && filterDirectiveTypes.includes(k['showForm']['type'])) this.hasFilterDirective = true
    })
  }
}
