<template>
  <v-chart
    v-if="ready"
    class="pie-chart"
    :forceFit="true"
    :height="height"
    padding="auto"
    :data="dataSource"
    :scale="scale"
    :theme="theme">
    <v-tooltip :showTitle="false" :dataKey="defaultDimension + '*percent'"/>
    <v-legend :dataKey="defaultDimension"/>
    <v-pie position="percent" :color="defaultDimension" :style="pieStyle" :label="pieLabelConfig"/>
    <v-coord type="theta" :radius="0.8"/>
  </v-chart>
</template>
<script>
import { debounce } from 'lodash'

const DataSet = require('@antv/data-set')

export default {
  name: 'PieChart',
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      dataSource: [],
      defaultDimension: '',
      defaultMetrics: '',
      adjust: [{
        type: 'dodge',
        marginRatio: 1 / 32
      }],
      scale: [{
        dataKey: 'percent',
        min: 0,
        formatter: '.0%'
      }],
      pieStyle: {
        stroke: '#fff',
        lineWidth: 1
      }
    }
  },
  computed: {
    title () {
      return this.reportData.title
    },
    pieLabelConfig () {
      return ['percent', {
        formatter: (val, item) => {
          return item.point[this.defaultDimension] + ': ' + val
        }
      }]
    }
  },
  mounted() {
    this.defaultDimension = this.reportData.dimensions[0]
    this.defaultMetrics = this.reportData.metrics[0]
    const dvPie = new DataSet.View().source(this.reportData.dataItems)
    dvPie.transform({
      type: 'percent',
      field: this.defaultMetrics,
      dimension: this.defaultDimension,
      as: 'percent'
    })
    this.dataSource = dvPie.rows
    this.ready = true
    this.chartRenderedDelay()
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
  .pie-chart {
  }
</style>
