<template>
  <v-chart
    class="column-chart"
    v-if="ready"
    :forceFit="true"
    :scale="scale"
    :height="canvasHeight"
    :data="dataSource"
    :padding="padding">
    <template v-if="facetField">
      <table v-if="facetCol === 1" border="1" class="facet-table">
        <tr v-for="(facetItem, index) in facetArray" :key="index">
          <td ref="firstTr">{{ facetItem }}</td>
          <td>
            <!-- :data="dataSource.filter(item => item[facetField] === facetItem)" -->
            <v-chart
              v-if="ready"
              :forceFit="true"
              :height="trHeight"
              :data="facetArrGroup[index]"
              :scale="scale"
              :padding="facetPadding">
              <v-tooltip />
              <!-- <v-legend /> -->
              <v-coord type="rect" direction="BL" />
              <v-axis :dataKey="yAxisName" :title="yTitleStyle" :label="yAxisLabel" />
              <v-axis :dataKey="xAxisName" :label="tableXLabel" />
              <!-- <v-axis :dataKey="xAxisName" :tickLine="tickLine" /> -->
              <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
              <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
              <v-bar v-else :position="xAxisName + '*' + yAxisName" />
            </v-chart>
          </td>
        </tr>
        <!-- 最后一行横坐标 -->
        <tr>
          <td ref="lastTr"></td>
          <td>
            <!-- dataSource.filter(item => item[facetField] === facetArray[0]) -->
            <v-chart
              v-if="ready"
              :forceFit="true"
              :height="trBottomHeight"
              :data="facetArrGroup[0]"
              :scale="scale"
              :padding="facetPaddingFinal">
              <v-tooltip />
              <v-coord type="rect" direction="BL" />
              <v-legend :offsetY="facetPaddingFinal[2] - 40" :clickable="false"/>
              <v-axis :dataKey="yAxisName" title="" :label="yAxisLabel" />
              <v-axis :dataKey="xAxisName" :label="tableXLabelFinal" />
              <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
              <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
              <v-bar v-else :position="xAxisName + '*' + yAxisName" />
            </v-chart>
          </td>
        </tr>
      </table>
      <!-- <v-facet type="list" :cols="facetCol" :fields="[facetField]" :padding="10" :colTitle="facetTitle">
        <v-facet-view>
          <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
          <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
          <v-bar v-else :position="xAxisName + '*' + yAxisName" />
        </v-facet-view>
      </v-facet> -->
    </template>
    <template v-else>
      <v-tooltip />
      <v-axis :dataKey="yAxisName" :title="{ text: yAxisTitle }" :label="yAxisLabel" />
      <!-- <v-axis :dataKey="xAxisName" :label="label" /> -->
      <v-axis useHtml :dataKey="xAxisName" :label="maxXtagLength > 8 ? labelHtml : label"/>
      <v-legend :offsetY="padding[2]-10-46" />
      <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
      <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
      <v-bar v-else :position="xAxisName + '*' + yAxisName" />
    </template>
    <v-guide v-if="reportData['predictStart']" type="region" :start="[reportData['predictStart'], 'min']" :end="[reportData['predictEnd'], 'max']" />
  </v-chart>
</template>
<script>
// https://viserjs.gitee.io/demo.html
import { simplifyNumber } from '@/utils/numberUtil'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import _, { debounce } from 'lodash'
const DataSet = require('@antv/data-set')
/**
 * 只支持：
 * 1个指标，或者1~2个维度
 * 1+个指标相同单位，1~2个维度
 */
export default {
  name: 'ColumnChart',
  components: {},
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    seriesType: {
      type: String,
      default: 'Group'
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      ready: false,
      canvasHeight: 300,
      dataSource: [],
      xAxisName: '',
      yAxisName: '',
      yAxisTitle: '',
      seriesName: '',
      seriesArray: [],
      xAxisLen: 0,
      seriesLen: 0,
      facetCol: 1,
      facetField: '',
      facetLen: 0,
      xAxisTags: [],
      morespace: 40,
      adjust: [
        {
          type: 'dodge',
          marginRatio: 1 / 32
        }
      ],
      facetArray: [],
      padding: [20, 30, 150, 65],
      facetPadding: [15, 50, 5, 85],
      facetPaddingFinal: [-60, 50, 55, 85],
      trBottomHeight: 30,
      facetArrGroup: [],
      trHeight: 100,
      maxXtagLength: 0
      // xAxisArray: []
    }
  },
  computed: {
    scale () {
      return [
        {
          dataKey: this.xAxisName,
          type: 'cat',
          tickCount: this.maxXtagLength > 8 ? (this.xAxisLen > 6 ? 6 : this.xAxisLen) : (this.xAxisLen > 12 ? 12 : this.xAxisLen),
          sync: false
        },
        {
          dataKey: this.yAxisName,
          tickCount: 5,
          max: Math.max.apply(Math, this.dataSource.map(x => x[this.yAxisName])),
          min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName])),
          sync: true
        },
        {
          dataKey: this.facetField,
          sync: true
        }
      ]
    },
    yTitleStyle () {
      return {
        text: this.yAxisTitle,
        offset: 70
      }
    },
    yAxisLabel () {
      return {
        formatter (val) {
          return simplifyNumber(val)
        },
        textStyle: {
          fontSize: '0.75em'
        }
      }
    },

    label () {
      return {
        autoRotate: false,
        rotate: this.maxXtagLength > 6 ? 70 : 45,
        offset: 15,
        textStyle: {
          fontSize: '0.75em',
          textAlign: 'start'
        }
      }
    },
    labelHtml () {
      return {
        htmlTemplate: (text, item, index) => {
          return `<div class="label-html-text">${text}</div>`
        }
      }
    },
    tableXLabel () {
      return {
        formatter() {
          return ''
        }
      }
    },
    tableXLabelFinal () {
      return {
        autoRotate: false,
        rotate: this.maxXtagLength > 6 ? 70 : 45,
        offset: 25,
        textStyle: {
          fontSize: '0.75em',
          textAlign: 'start'
        }
      }
    }
    // facetTitle () {
    //   if (this.facetCol === 1) {
    //     console.log('1111111111111')
    //     return {
    //       // offsetX: 0,
    //       offsetX: (this.facetWidth / 2) * -1 - 25,
    //       // offsetX: (this.facetWidth / 2) * (-1) + 10
    //       offsetY: this.height / this.seriesLen / 2
    //     }
    //   } else {
    //     return {
    //       // offsetX: 0
    //       // offsetX: (this.facetWidth / 2) * (-1) - 25,
    //       offsetX: (this.facetWidth / 2) * -1 + 251
    //       // offsetY: this.height / this.seriesLen / 2 - 30
    //     }
    //   }
    // }
  },
  mounted () {
    const _unit = this.reportData.units[0].unit
    const _unitEnclosed = _unit ? `（${_unit}）` : ''
    this.xAxisName = this.xAxis || this.reportData.dimensions[0]
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    if (this.reportData.metrics.length > 1) {
      // 如果指标数量大于1，用指标分类成系列（color）
      const dv = new DataSet.View().source(this.reportData.dataItems)
      dv.transform({
        type: 'fold',
        fields: this.reportData.metrics,
        key: 'metricsName',
        value: 'metricsValue'
      })
      this.seriesName = 'metricsName'
      this.yAxisName = 'metricsValue'
      this.yAxisTitle = this.reportData.metrics.join('、') + _unitEnclosed
      this.dataSource = dv.rows
      if (this.reportData.dimensions.length === 2) {
        this.facetField = this.reportData.dimensions.find((item) => item !== this.xAxisName)
      }
    } else {
      this.yAxisName = this.reportData.metrics[0]
      this.yAxisTitle = this.reportData.metrics[0] + _unitEnclosed
      this.dataSource = this.reportData.dataItems
      if (this.reportData.dimensions.length === 2) {
        this.seriesName = this.reportData.dimensions.find((item) => item !== this.xAxisName)
      }
    }
    this.xAxisLen = _.uniqBy(this.dataSource, this.xAxisName).length
    this.seriesArray = _.uniqBy(this.dataSource, this.seriesName).map(item => item[this.seriesName])
    this.seriesLen = _.uniqBy(this.dataSource, this.seriesName).length
    if (this.facetField) {
      this.facetArray = _.uniqBy(this.dataSource, this.facetField).map(item => item[this.facetField])
      this.facetLen = this.facetArray.length
      this.$nextTick(() => {
        // console.log('this.ref', this.$refs)
        const firstTrArr = this.$refs['firstTr']
        const lastTrArr = this.$refs['lastTr']
        // console.log('this.firstTrArr', firstTrArr)
        const firstTrHeightArr = firstTrArr.map(item => item.offsetHeight)
        const lastTrHeight = lastTrArr.offsetHeight
        const _height = firstTrHeightArr.reduce(function(prev, cur, index, array) {
          return prev + cur
        }) + lastTrHeight + 10 // 表格开头有10px
        // console.log('seriesTitleHeightArr', seriesTitleHeightArr)
        console.log('_height', _height)
        console.log('lastTrHeight', lastTrHeight)
        console.log('firstTrHeightArr', firstTrHeightArr)
        if (_height > this.height) {
          this.canvasHeight = _height
        } else {
          this.canvasHeight = this.height
          this.trHeight = (this.canvasHeight - lastTrHeight) / this.facetLen - 7 * this.facetLen - 5 // 减去每行比图表多出的空间

          // console.log('this.height', firstTrHeightArr)
          console.log('this.height', this.height)
          console.log('this.trHeight', this.trHeight)
        }
      })
      this.getFacetDataArr()
    } else {
      this.canvasHeight = this.height
    }
    this.dataSource.forEach((element, index) => {
      if (element[this.yAxisName] === Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName]))) {
        console.log('min', index)
      } else if (element[this.yAxisName] === Math.max.apply(Math, this.dataSource.map(x => x[this.yAxisName]))) {
        console.log('max', index)
      }
    })

    this.ready = true
    this.morespace =
      this.reportData.series.length < 20
        ? 45
        : this.reportData.series.length < 40
          ? 105
          : this.reportData.series.length < 60
            ? 155
            : 200
    this.getPaddingBottom()
    this.getPaddingRight()
    this.chartRenderedDelay()
  },
  methods: {
    getFacetDataArr () {
      this.facetArray.forEach((facetItem, index) => { // 得到子图数据数组 arrGroup
        this.facetArrGroup.push(this.dataSource.filter(item => item[this.facetField] === facetItem))
      })
      // console.log('arrGroup', this.facetArrGroup)
      const sortXTagesArr = this.xAxisTags.sort()
      // console.log('sortXTagesArr', sortXTagesArr)
      this.facetArrGroup = this.facetArrGroup.map(arr => {
        const newArr = []
        const facetField = arr[0][this.facetField]
        sortXTagesArr.forEach(xTag => {
          this.seriesArray.forEach(seriesName => { // 多指标
            const _item = arr.find(item => item[this.xAxisName] === xTag && item[this.seriesName] === seriesName) // 指标和X都相等的项存在
            if (!_item) {
              const obj = {}
              obj[this.xAxisName] = xTag
              obj[this.yAxisName] = 0
              obj[this.seriesName] = seriesName
              obj[this.facetField] = facetField
              newArr.push(obj)
            } else {
              newArr.push(_item)
            }
          })
        })
        return newArr
      })
    },
    getPaddingBottom () {
      this.maxXtagLength = 0
      let wordLength = 0
      this.xAxisTags.forEach((item) => {
        const len = getStrFullLength(item)
        if (len > wordLength) {
          wordLength = len
        }
      })
      this.maxXtagLength = wordLength / 2
      const wordWidth = this.maxXtagLength > 6 ? 11 : 16 // 每个字节5.5长度，maxLength为字节长度
      if (this.maxXtagLength > 8) {
        this.padding[2] = 15 + this.maxXtagLength / 2 * 15
        if (this.reportData.metrics.length > 1) this.padding[2] += 25
      } else {
        this.padding[2] = this.maxXtagLength * wordWidth
      }
      if (this.seriesLen > 1) this.padding[2] += 25// 加上图例的高度
      this.trBottomHeight = this.maxXtagLength * wordWidth + 10// 每个字11
      this.facetPaddingFinal[2] = 15 + this.trBottomHeight
    },
    getPaddingRight () {
      const len = this.xAxisTags.length
      let i = len - 2
      let tag = 1
      let maxLength = getStrFullLength(this.xAxisTags[len - 1])
      const wordWidth = this.maxXtagLength > 6 ? 2.5 : 6 // 每个字节2.5宽度，maxLength为字节长度
      const wordNum = this.maxXtagLength > 6 ? 5 : 2
      while (i >= 0) {
        // 5表示当前一个比后一个多5个字以上时，才会导致前一个标签为到右边最远的标签
        // 将离右边最近的标签与其之前的标签对比，得到往右延伸更远的标签
        if (maxLength + wordNum * tag < getStrFullLength(this.xAxisTags[i])) {
          maxLength = getStrFullLength(this.xAxisTags[i])
          tag = 1
        }
        i--
        tag++
      }
      this.facetPaddingFinal[1] = maxLength * wordWidth - 6 // 减去为0时已经显示的部分
      this.facetPadding[1] = maxLength * wordWidth - 6
      if (this.maxXtagLength < 8) {
        this.padding[1] = maxLength * wordWidth - 6
      }
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.column-chart {
  position: relative;
  .g-labels {
    width: 100%;
    .g-label {
      width: calc((100% - 65px - 65px) / 6);
      height: 20px;
      .label-html-text {
        font-size: 0.75em;
        transform: scale(0.9);
        color: #808080;
        text-align: center;
      }
    }
  }
  .facet-table {
    width: 100%;
    position: absolute;
    top: 10px;
    border: none;
    border-color: #eeeeee;
    z-index: 2;
    border-spacing: 0;
    // border-left: none;
    // border-right: none;
    // td {
    //   height: 238px;
    // }
    tr:first-child {
      td {
        border-top-style: solid !important;
        border-top-color: #eeeeee !important;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
    tr {
      height: 100%;
      td:first-child {
        text-align: center;
        border-left: none;
        border-top: none;
        border-width: 1px;
        width: 20%;
        font-size: 0.75em;
      }
      td:last-child {
        border-right: none;
        border-left: none;
        border-top: none;
        font-size: 0.75em;
        border-width: 1px;
      }
    }
  }
}
</style>
