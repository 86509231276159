<template>
  <div
    class="dashboard-view view dashboard-view-wrapper"
    :style="viewStyle"
    v-resize:throttle.100="containerResize">
    <div class="dashboard-view-bg" :style="bgStyle"/>
    <div class="dashboard-view-inner">
      <template v-if="!dataLoading">
        <template v-for="child in dashboardModel.children">
          <div
            :key="child.uuid"
            class="dashboard-child"
            :style="childStyle(child)">
            <dashboard-child :model="child" :dashboard-model="dashboardModel"/>
          </div>
        </template>
      </template>
    </div>
    <div v-if="dataLoading" class="spinner">
      <a-spin size="large" tip="加载仪表盘数据..."/>
    </div>
  </div>
</template>
<script>
import './style/dashboard-view-new.less'
import './style/dashboard-child.less'
import './style/dashboard.less'
import DashboardChild from '@/views/dashboard/DashboardChildNew'
import resize from 'vue-resize-directive'
import PropertyHelp from './data/PropertyHelp'

/**
 *
 */
export default {
  name: 'DashboardViewNew',
  directives: {
    resize
  },
  components: {
    DashboardChild
    // ChartItem
    // FilterItem,
    // ImageItem,
    // TextItem,
    // DashboardChartTypes,
    // SpeechSmartInput
  },
  props: {
    dashboardModel: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      // id: null,
      // dashboardModel: {
      //   children: []
      // },
      dataLoading: false,
      showOverlyChart: false,
      gridLayoutColNum: 100,
      viewWidth: 1024,
      viewHeight: 720,
      xRatio: 1,
      yRatio: 1,
      colWidth: 1,
      rowHeight: 1,
      showNewQuery: false,
      interactInputVisible: false,
      keyword: '',
      newQueryModalVisible: false,
      contextTarget: null
    }
  },
  computed: {
    bgStyle() {
      if (this.dashboardModel) {
        const backgroundStyle = PropertyHelp.getBackgroundStyle(this.dashboardModel)
        const borderStyle = PropertyHelp.getBorderStyle(this.dashboardModel)
        return Object.assign({}, backgroundStyle, borderStyle)
      } else {
        return {}
      }
      // return {}
    },
    viewStyle() {
      return {
        width: '100%',
        height: this.dashboardModel.stageHeight * this.yRatio + 'px'
      }
    }
  },
  methods: {
    childStyle(child) {
      const { x, y, w, h, z } = child
      const oneColWidth = this.dashboardModel.stageWidth / 20
      const width = w * this.xRatio * oneColWidth
      const height = h * this.yRatio
      const left = x * this.xRatio * oneColWidth
      const top = y * this.yRatio
      const paddingObj = this.getChildPaddingStyle(child)
      return {
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
        zIndex: z,
        ...paddingObj
      }
    },
    getChildPaddingStyle (child) {
      const styleObj = {}
      // 垂直部分
      const maxY = Math.max(...this.dashboardModel.children.map(item => { return item['y'] }))
      if (child.y === 0 && child.y === maxY) { // 第一行且最后一行
        styleObj.paddingTop = this.dashboardModel.space + 'px'
        styleObj.paddingBottom = this.dashboardModel.space + 'px'
      } else if (child.y === 0) { // 第一行
        styleObj.paddingTop = this.dashboardModel.space + 'px'
        styleObj.paddingBottom = this.dashboardModel.space / 2 + 'px'
      } else if (child.y === maxY) { // 最后一行
        styleObj.paddingTop = this.dashboardModel.space / 2 + 'px'
        styleObj.paddingBottom = this.dashboardModel.space + 'px'
      } else { // 中间行
        styleObj.paddingTop = this.dashboardModel.space / 2 + 'px'
        styleObj.paddingBottom = this.dashboardModel.space / 2 + 'px'
      }
      // 水平部分
      if (child.x === 0 && child.x + child.w === 20) { // 一列只有一个
        styleObj.paddingLeft = this.dashboardModel.space + 'px'
        styleObj.paddingRight = this.dashboardModel.space + 'px'
      } else if (child.x === 0) { // 第一列
        styleObj.paddingLeft = this.dashboardModel.space + 'px'
        styleObj.paddingRight = 0
      } else if (child.x + child.w === 20) { // 最后一列
        styleObj.paddingLeft = this.dashboardModel.space / 2 + 'px'
        styleObj.paddingRight = this.dashboardModel.space + 'px'
      } else { // 中间列
        styleObj.paddingLeft = this.dashboardModel.space / 2 + 'px'
        styleObj.paddingRight = 0
      }
      return styleObj
    },
    // childStyle(child) {
    //   const { x, y, w, h, z } = child
    //   const margin = this.dashboardModel.space / 2
    //   const oneColWidth = this.dashboardModel.stageWidth / 20
    //   const width = w * this.xRatio * oneColWidth - margin * 2
    //   const height = h * this.yRatio - margin * 2
    //   const left = x * this.xRatio * oneColWidth
    //   const top = y * this.yRatio
    //   return {
    //     width: `${width}px`,
    //     height: `${height}px`,
    //     left: `${left}px`,
    //     top: `${top}px`,
    //     margin: `${margin}px`,
    //     zIndex: z
    //   }
    // },
    containerResize() {
      console.log(this.$el)
      const { clientWidth, clientHeight } = this.$el
      const { stageWidth } = this.dashboardModel
      this.viewWidth = clientWidth
      this.viewHeight = clientHeight
      this.xRatio = this.viewWidth / stageWidth
      this.yRatio = this.xRatio
    },
    // getDashboardById() {
    //   this.$api.dashboard.getDashboardById(this.id).then(res => {
    //     this.dashboardModel = JSON.parse(res['data'])
    //     this.containerResize()
    //   })
    // },
    onClickLeft () {
      window.history.go(-1)
    }
  },
  mounted() {
    this.containerResize()
    // this.id = this.$route.params['id']
    // if (this.id) {
    //   this.getDashboardById()
    // } else {
    //   this.$router.replace({ name: 'DashboardList' })
    // }
  },
  watch: {
    dashboardModel: {
      handler () {
        this.containerResize()
      }
    }
  }
}
</script>
<style lang="less"></style>
