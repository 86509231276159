<template>
  <div class="history-chart-item">
    <div class="chart-wrapper van-hairline--surround">
      <div v-if="dataLoading" class="chart-loading">
        <img :src="historyData['thumbnail']" alt="">
        <van-loading class="chart-spinner" />
      </div>
      <div v-else>
        <speech-smart-chart
          @touchstart.native="getDbclickContent"
          ref="refSpeechSmartChart"
          class="speech-smart-chart"
          :uuid="historyData['id']"
          :chart-type="historyData['chartType']"
          :keyword="[historyData['searchWords']]"
          :reportChartData="reportChartData"
          :keywordMatch="keywordMatched['originData']"
          :xAxis="keywordMatched['adviseDatetimeAxis']"
          :keywordString="historyData['searchWords']"
          :isHistory="true"
          :height="historyData['h']"
          :keywordSearchHistoryId="historyData['id']"
          @removeHomePage="removeHomePage"
          :theme="theme" />
      </div>
    </div>
    <!-- <div class="search-words">{{ historyData['searchWords'] }}</div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SpeechSmartChart from '@/components/speech/SpeechSmartChart'
import KeywordMatched from '@/utils/keywordMatched'
import ReportChartData from '@/utils/reportData'

export default {
  name: 'ChartItem',
  components: {
    SpeechSmartChart
  },
  props: {
    historyData: {
      type: Object,
      default () {
        return {
          dataRequest: {
            matchKeywords: []
          }
        }
      }
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      dataLoading: true,
      reportChartData: {},
      clickNum: 0,
      clickTime: {
        timeA: '',
        timeB: ''
      }
    }
  },
  computed: {
    ...mapState({
      dataRegion: state => state.dataRegion.activeItem,
      inputKeyword: state => state.speech.inputKeyword,
      selectHistories: state => state.speech.selectHistories
    }),
    keywordMatched () {
      const { searchWords, dataRequest } = this.historyData
      return new KeywordMatched(searchWords, dataRequest.matchKeywords)
    }
  },
  methods: {
    getReportData () {
      const { dataRequest } = this.historyData
      this.$api.speech.getReportData(dataRequest).then(res => {
        // this.keywordMatched = new KeywordMatched(searchWords, dataRequest.matchKeywords)
        this.reportChartData = new ReportChartData(res[0])
      }).catch(() => {
      }).finally(() => {
        this.dataLoading = false
      })
    },
    startSelect () {
      this.$store.dispatch('speech/setSelectHistories', true)
    },
    cancelSelect () {
      this.$store.dispatch('speech/setSelectHistories', false)
    },
    deleteItem (item) {
      this.$api.speech.deleteSearchTrack(this.type, item['id']).then(res => {
        const { content } = this.originData
        const _index = content.findIndex(i => i['id'] === item['id'])
        if (_index > -1) content.splice(_index, 1)
        console.log(item)
        this.formatData()
      })
    },
    removeHomePage () {
      this.$api.speech.deleteSearchTrack('headlines', this.historyData['id']).then(res => {
        this.$emit('remove')
      })
    },
    getDbclickContent () { // 如果是主页时，双击跳转搜索结果页面
      if (this.clickNum === 0) { // 第一次点击
        this.clickTime.timeA = new Date()
        this.clickNum++
      } else if (this.clickNum === 1) { // 第二次点击
        this.clickTime.timeB = new Date()
        if (Math.abs(this.clickTime.timeA - this.clickTime.timeB) < 300) {
          console.log('clickTwo')
          //  双击 function
          this.$store.commit('speech/setHistoryLocation', { dataRegionId: this.dataRegion.id, historyLocation: -1 })
          this.$router.push({ name: 'Speech', params: { historyData: this.historyData } })
        } else {
          this.clickTime.timeA = new Date()
        }
        this.clickNum = 0
      }
    }
  },
  mounted() {
    this.getReportData()
  }
}
</script>
<style lang="less">
.history-chart-item {
  padding: 12px;
  overflow: hidden;
  .chart-wrapper {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-width: 0 !important;
    &::after {
      border-width: 0
    }
    // min-height: 64vw;
    .chart-loading {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
      }
      .chart-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .search-words {
    font-size: 14px;
    line-height: 2em;
  }
}
</style>
