<template>
  <div class="key-value-dashboard-chart">
    <van-empty v-if="dataSource.length === 0" image-size="80"/>
    <div class="key-value-item" v-for="item in dataSource" :key="item['key']" :style="contentStyle">
      <div class="value">{{ item['value'] }} {{ item['unit'] }}</div>
      <!-- <div class="name">{{ item['name'] }}</div> -->
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { formatNumberThousands } from '@/utils/numberUtil'
import { getFontStyle } from '@/utils/PropertyHelp'
export default {
  name: 'KeyValueChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {
          content: '',
          titleStyle: {
            interactive: true,
            height: 30,
            hidden: true
          },
          contentStyle: {},
          chartType: ''
        }
      }
    },
    model: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    }
  },
  computed: {
    dataItems () {
      if (this.reportData['dataItems'] && this.reportData['dataItems'].length > 0) {
        return this.reportData['dataItems'][0]
      } else {
        return {}
      }
    },
    dataUnits () {
      return this.reportData['units'] || []
    },
    dataSource () {
      const _result = []
      const _keys = Object.keys(this.dataItems)
      _keys.forEach((_key) => {
        const _val = this.dataItems[_key]
        _result.push({
          key: uuidv4(),
          name: _key,
          value: formatNumberThousands(_val),
          unit: this.getUnitByMetrics(_key)
        })
      })
      return _result
    },
    contentStyle () {
      if (this.model) {
        if (this.model.contentStyle) {
          return getFontStyle(this.model.contentStyle)
        }
      }
      return {}
    }
  },
  methods: {
    getUnitByMetrics (metrics) {
      const _exist = this.dataUnits.find(item => item['metrics'].indexOf(metrics) > -1)
      return _exist ? _exist['unit'] : ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('chartRendered')
    })
  }
}
</script>
<style lang="less">
  .key-value-dashboard-chart {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .key-value-item {
      flex: 1;
      width: 100%;
      font-size: 1em;
      color: #000;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border-top: 1px solid #efefef;

      &:first-child {
        border: none;
      }

      .value {
        width: 100%;
      }
    }
    // .key-value-item .value {
    //   font-size: 1em;
    //   color: #000;
    // }
    // .key-value-item .name {
    //   font-size: .75em;
    //   color: #666;
    // }
  }
  .theme-dark {
    .key-value-dashboard-chart {
      .key-value-item {
        background: none;
        border-top: 1px solid rgba(100, 100, 100, 0.4);
        .value {
          // color: #999;
        }
        .name {
          // color: #666
        }
      }
    }
  }
</style>
