<template>
  <div class="home-page-index">
    <van-nav-bar
      :style="{'padding-top': statusBarHeight + 'px'}"
      placeholder
      safe-area-inset-top>
      <template #title>
        <DataRegionPopover />
      </template>
      <template #right>
        <!-- <van-icon name="question-o" /> -->
        <!-- <van-icon name="home-o" @click="clearSearchContent"/> -->
      </template>
    </van-nav-bar>
    <div class="home-page-content" :style="{height: `calc(100vh - 90px - ${moreHeight}px)`}">
      <iframe
        v-if="form.defaultPageType === '网页链接'"
        class="web-link-page"
        frameborder="0"
        scrolling="yes"
        :src="form.webLink"></iframe>
      <DashboardViewNew v-else-if="form.defaultPageType === '仪表盘'" :dashboardModel="dashboardModel"/>
      <ChartList v-else />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ChartList from '@/components/homePage/ChartList'
import '@/components/homePage/homeStyle.less'
import DataRegionPopover from '@/components/global/DataRegionPopover'
import DashboardViewNew from '@/views/dashboard/DashboardViewNew'

export default {
  name: 'HomePage',
  components: {
    ChartList,
    DataRegionPopover,
    DashboardViewNew
  },
  data() {
    return {
      percent: 50,
      showPopover: false,
      form: {
        dataRegionId: '',
        dataRegionName: '',
        defaultPageType: '',
        keyword: '',
        webLink: '',
        dashboardId: '',
        dashboardName: ''
      },
      // dashboardData: {
      //   title: '仪表盘'
      // },
      dashboardModel: {
        children: []
      }
    }
  },
  computed: {
    ...mapState({
      activeDataRegionId: state => state.dataRegion.activeItem.id,
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight,
      settings: state => state.user.settings
    }),
    mobileSettings () {
      return this.settings['mobile'] || {}
    }
  },
  methods: {
    getSettings () {
      this.$api.user.getSettings().then(res => {
        if (res && res['settings'] && res['settings']) {
          this.$store.commit('user/setSettings', JSON.parse(res['settings']))
          this.getDefaultSetting()
        }
      }).catch((e) => {})
    },
    getDefaultSetting () {
      this.form.dataRegionId = this.mobileSettings.dataRegionId
      // if (this.form.dataRegionId !== '') {
      //   this.$store.commit('dataRegion/setActiveId', this.form.dataRegionId)
      // }
      this.form.defaultPageType = this.mobileSettings.defaultPageType
      if (this.form.defaultPageType === '搜索页面') {
        this.form.keyword = this.mobileSettings.extraParam
        // this.$router.replace({ name: 'Speech', params: { keyword: this.form.keyword } })
      } else if (this.form.defaultPageType === '网页链接') {
        this.form.webLink = this.mobileSettings.extraParam
      } else if (this.form.defaultPageType === '仪表盘') {
        this.form.dashboardId = this.mobileSettings.extraParam
        this.getDashboardById()
      }
    },
    getDashboardById() {
      this.$api.dashboard.getDashboardById(this.form.dashboardId).then(res => {
        // this.dashboardData = res
        this.dashboardModel = JSON.parse(res['data'])
        // this.containerResize()
      })
    }
  },
  mounted() {
    this.getSettings()
  }
}
</script>
<style lang="less">
.home-page-index {
  // background-image: url("~@/assets/background.png");
  // background-size: 100% 40%;
  // background-repeat: no-repeat;
  .van-nav-bar {
    height: 40px !important;
    background-image: url("~@/assets/background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    // &::after {
    //   border-bottom-width: 0
    // }
    .van-nav-bar__content {
      height: 40px;
    }
  }
  .home-page-content {
    .web-link-page {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
