<template>
  <div v-if="ready" class="table-chart">
    <div class="table-chart-head">
      <div class="table-chart-head-extra">共 {{ dataSource.length }} 条数据</div>
    </div>
    <table class="table-chart-table van-hairline--top van-hairline--left">
      <thead>
        <tr>
          <th
            class="van-hairline--right van-hairline--bottom"
            v-for="col in columns"
            :key="col.key"
            @click="col.axisType === 'dimension'? filterData(col) :sortTable(col)">
            <div class="th-div">
              <div class="th-text"> {{ col.title }} </div>
              <van-icon v-if="col.axisType === 'dimension'" name="filter-o" class="filter-icon"/>
              <div v-else style="display: inline-block; width: 12px; margin-left: 2px">
                <div class="triangle_border_up">
                  <span :style="{borderColor:tableSortCol.key===col.key?tableSortCol.type==='up'?'transparent transparent #97999a':'' :''}"></span>
                </div>
                <div class="triangle_border_down">
                  <span :style="{borderColor:tableSortCol.key===col.key?tableSortCol.type==='down'?'#97999a transparent transparent':'' :''}"></span>
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in currentData" :key="data.key">
          <td
            class="van-hairline--right van-hairline--bottom"
            v-for="col in columns"
            :key="col.key">
            <div>{{ simplifyNumberTemp(data[col.title]) + (col.unit || '') }}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div ref="tablePagination" class="table-chart-pagination">
      <van-pagination
        v-model="currentPage"
        :total-items="dataSource.length"
        :items-per-page="tablePageSize"
        :show-page-size="showPageSize"
        force-ellipses
        @change="getCurrentData">
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
      </van-pagination>
    </div>
    <van-dialog
      v-model="showFilterModal"
      title="请选择要过滤的项"
      show-cancel-button
      @confirm="confirmFilter"
      @cancel="cancelFilter"
      class="filter-modal">
      <div style="padding: 10px 15px">
        <!-- <van-button type="primary" @click="checkAll">全选</van-button>
      <van-button type="info" @click="toggleAll">反选</van-button> -->
        <van-checkbox-group v-model="filterResult">
          <van-row gutter="20">
            <van-col span="12" v-for="item in filterItems" :key="item" class="filter-check-item">
              <van-checkbox :name="item">{{ item }}</van-checkbox>
            </van-col>
          </van-row>
        </van-checkbox-group>
      </div>
    </van-dialog>
  </div>
</template>
<script>
// import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
// import { getStrFullLength } from '@/utils/utils'
import { getDiffValue, getStrFullLength } from '@/utils/utils'

export default {
  name: 'TableChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      ready: false,
      tablePageSize: 10,
      columns: [],
      dataSource: [],
      beforeSortDataSource: [],
      currentData: [],
      currentPage: 1,
      showPageSize: 5,
      tableSortCol: {
        key: '',
        type: ''
      },
      showFilterModal: false,
      filterResult: [],
      filterItems: [],
      fliterDataIndex: '',
      lastFliterDataIndex: ''
    }
  },
  methods: {
    getCurrentData () {
      this.currentData = this.dataSource.slice((this.currentPage - 1) * this.tablePageSize, (this.currentPage - 1) * this.tablePageSize + this.tablePageSize)
      // this.tableSortCol.key = ''
      // this.tableSortCol.type = ''
    },
    calcShowPageNum () {
      const paginationWidth = this.$refs.tablePagination.offsetWidth
      // console.log('paginationWidth', paginationWidth)
      if (paginationWidth > 425) {
        this.showPageSize = Math.floor((paginationWidth - 100 * 2) / 36) - 2
      } else {
        this.showPageSize = Math.floor((paginationWidth - 50 * 2) / 36) - 2
      }
    },
    filterData (col) {
      this.fliterDataIndex = col['dataIndex']
      this.filterResult = col['filterResult']
      // const dataItemsIndexLen = getDiffValue(this.reportData.dataItems, this.fliterDataIndex).length
      // const dataSourceIndexArray = getDiffValue(this.dataSource, this.fliterDataIndex)
      // if (this.lastFliterDataIndex === this.fliterDataIndex && dataSourceIndexArray.length !== dataItemsIndexLen) {
      //   this.filterResult.push(...dataSourceIndexArray)
      // }

      this.filterItems = getDiffValue(this.reportData.dataItems, col['dataIndex'])
      this.showFilterModal = true
    },
    confirmFilter () {
      const column = this.columns.find(item => item['dataIndex'] === this.fliterDataIndex)
      column.filterResult = JSON.parse(JSON.stringify(this.filterResult))
      this.getNewDataSource()
      this.getFilteredSortData()
      this.getCurrentData()
      this.currentPage = 1
    },
    getNewDataSource () { // 获取筛选后的数据
      // let dataSourceArray = JSON.parse(JSON.stringify(this.dataSource))
      this.dataSource = JSON.parse(JSON.stringify(this.reportData.dataItems))
      this.columns.forEach(item => {
        const dataIndex = item.dataIndex
        if (item.filterResult.length > 0) {
          const dataArray = []
          item.filterResult.forEach(result => {
            console.log('result', result)
            dataArray.push(...this.dataSource.filter(item => item[dataIndex] === result))
          // console.log('dataArray', dataArray)
          })
          this.dataSource = dataArray
        }
        console.log('dataSource', this.dataSource)
        // dataSourceArray = dataArray
        // console.log('dataSourceArray', dataSourceArray)
      })
      this.beforeSortDataSource = JSON.parse(JSON.stringify(this.dataSource))
      console.log('dataSource', this.dataSource)
    },
    getFilteredSortData () { // 排序以后再筛选，筛选的结果也排序好
      switch (this.tableSortCol.type) {
        case 'up':
          const colUp = this.columns.find(item => item['key'] === this.tableSortCol.key)
          this.dataSource.sort(function(a, b) { // 进行排序
            const value1 = a[colUp.title]
            const value2 = b[colUp.title]
            if (typeof value1 === 'number' && typeof value2 === 'number') {
              return value1 - value2
            } else {
              return value1.localeCompare(value2)
            }
          })
          break
        case 'down':
          const colDown = this.columns.find(item => item['key'] === this.tableSortCol.key)
          this.dataSource.sort(function(a, b) { // 进行排序
            const value1 = a[colDown.title]
            const value2 = b[colDown.title]
            if (typeof value1 === 'number' && typeof value2 === 'number') {
              return value2 - value1
            } else {
              return value2.localeCompare(value1)
            }
          })
          break
      }
    },
    cancelFilter () {
      this.fliterDataIndex = this.lastFliterDataIndex
    },
    sortTable (col) { // 点击需要排序的列进行排序
      if (this.tableSortCol.key !== col.key) {
        this.tableSortCol.key = col.key
        this.tableSortCol.type = ''
      }
      if (this.tableSortCol.type === '') {
        this.tableSortCol.type = 'up'
        this.dataSource.sort(function(a, b) { // 进行排序
          const value1 = a[col.title]
          const value2 = b[col.title]
          if (typeof value1 === 'number' && typeof value2 === 'number') {
            return value1 - value2
          } else {
            return value1.localeCompare(value2)
          }
        })
      } else if (this.tableSortCol.type === 'up') {
        this.tableSortCol.type = 'down'
        this.dataSource.reverse() // 如果该列已经进行排序过了，则直接对其反序排列
      } else if (this.tableSortCol.type === 'down') {
        this.tableSortCol.type = ''
        this.dataSource = JSON.parse(JSON.stringify(this.beforeSortDataSource))
      }
      this.getCurrentData()
    },
    simplifyNumberTemp (val) {
      if (typeof val === 'number') {
        return simplifyNumber(val)
      } else {
        return val
      }
    }
  },
  mounted () {
    this.columns = JSON.parse(JSON.stringify(this.reportData.columns))
    this.columns.forEach(item => {
      item.width = getStrFullLength(item.title) * 14
      item.filterResult = []
    })
    this.dataSource = JSON.parse(JSON.stringify(this.reportData.dataItems))
    this.beforeSortDataSource = JSON.parse(JSON.stringify(this.dataSource))
    // this.dataSource.forEach(dataItem => {
    //   Object.keys(dataItem).forEach(key => {
    //     const _column = _columns[key]
    //     let keyDataItem = dataItem[key]
    //     if (_column.axisType && _column.axisType === 'metrics') {
    //       // dataItem[key] = simplifyNumber(dataItem[key]) + (_column.unit || '')
    //       keyDataItem = simplifyNumber(dataItem[key]) + (_column.unit || '')
    //     }
    //     _column.width = Math.max(_column.width, getStrFullLength(keyDataItem) * 14)
    //     // _column.width = Math.max(_column.width, getStrFullLength(dataItem[key]) * 14)
    //   })
    //   dataItem.key = uuidv4()
    // })
    // calc table pagesize
    // this.tablePageSize = Math.floor(this.height / 40) - 2
    this.currentData = this.dataSource.slice((this.currentPage - 1) * this.tablePageSize, (this.currentPage - 1) * this.tablePageSize + this.tablePageSize)
    this.ready = true
    this.$nextTick(() => { // 修改分页器页码数量
      const _this = this
      this.calcShowPageNum()
      window.onresize = function () {
        _this.calcShowPageNum()
      }
    })
    this.chartRenderedDelay()
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
  .table-chart {
    max-height: 100%;
    .table-chart-head {
      padding: 0 12px;
      font-size: 14px;
      line-height: 2em;
    }
    .table-chart-head-extra {
      text-align: right;
    }
    .table-chart-table {
      border-spacing: 0;
      border: none;
      width: 100%;
      td, th {
        font-size: 0.75rem;
        line-height: 28px;
        text-align: left;
      }
      thead tr {
        background-color: #ededed;
        .th-div {
          padding: 0 5%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .th-text {
            width: calc(100% - 10px);
            display: inline-block;
            font-weight: 500;
          }
          .filter-icon {
            color: #97999a;
          }
          .triangle_border_up{
            width: 0;
            height: 0;
            border-width: 0px 5px 7px;
            border-style: solid;
            border-color: transparent transparent #97999a;
            position: relative;
            margin: 0 0 1px 0;
          }
          .triangle_border_up span{
            display: block;
            width: 0;
            height: 0;
            border-width: 0 3px 5px;
            border-style: solid;
            border-color: transparent transparent #fff;
            position: absolute;
            top: 1px;
            left: -3px;
          }
          /*向下*/
          .triangle_border_down{
            width: 0;
            height: 0;
            border-width: 7px 5px 0px;
            border-style: solid;
            border-color: #97999a transparent transparent;
            position: relative;
            margin: 1px 0 0 0;
          }
          .triangle_border_down span{
            display: block;
            width: 0;
            height: 0;
            border-width: 5px 3px 0;
            border-style: solid;
            border-color: #fff transparent transparent;
            position: absolute;
            top: -6px;
            left: -3.1px;
          }
        }
      }
      tbody {
        color: #6c6b6b;
        tr:nth-child(even) {
          background-color: #f8f8f8;
        }
        td div {
          transform: scale(0.9);
        }
      }
    }
    &-pagination {
      padding: 12px;
      display: flex;
      justify-content: center;
    }
    .filter-modal {
      .van-dialog__header {
        font-size: 16px;
        padding-top: 12px;
      }
      .filter-check-item {
        padding-bottom: 5px;
        font-size: 12px;
        .van-checkbox__label {
          color: #6c6b6b;
        }
      }
    }
  }
</style>
