<template>
  <van-popup v-model="visible" position="bottom" get-container="body">
    <van-datetime-picker
      v-model="currentDate"
      type="year-month"
      title="请选择"
      :formatter="formatter"
      @cancel="cancel"
      @confirm="confirm"
    />
  </van-popup>
</template>
<script>
import moment from 'moment'

export default {
  name: 'DateSelector',
  components: {
  },
  props: {
    fields: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
      currentDate: ''
    }
  },
  computed: {
    localValue () {
      let _val = this.value
      _val = _val.replace('年', '-')
      _val = _val.replace('月', '-')
      _val = _val.replace('日', '-')
      _val = _val.replace('号', '-')
      _val = _val.replace(/-(\d)-/, '-0$1-')
      _val = _val.replace(/-(\d)-/, '-0$1-')
      _val = _val.replace(/-$/, '')
      return _val
    },
    start () {
      if (this.fields === 'year') {
        return '1970'
      } else if (this.fields === 'month') {
        return '1970-01'
      } else {
        return '1970-1-1'
      }
    },
    end () {
      const _date = new Date()
      if (this.fields === 'year') {
        return _date.getFullYear() + ''
      } else if (this.fields === 'month') {
        return _date.getFullYear() + '-' + (_date.getMonth() + 1)
      } else {
        return _date.getFullYear() + '-' + (_date.getMonth() + 1) + '-' + _date.getDate()
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    cancel () {
      this.visible = false
    },
    confirm (value) {
      console.log(moment(value).format('YYYY年MM月DD hh:mm:ss'))
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>

</style>
