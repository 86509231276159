<template>
  <v-chart
    v-if="ready"
    :forceFit="true"
    :scale="scale"
    :height="canvasHeight"
    :data="dataSource"
    :padding="padding"
    class="line-column-chart">
    <template v-if="facetField">
      <table border="1" class="facet-table">
        <tr v-for="(facetItem, index) in facetArray" :key="index">
          <td ref="seriesTitle">{{ index === facetLen ? '': facetItem }}</td>
          <td>
            <v-chart
              v-if="ready"
              :forceFit="true"
              :height="index === facetLen? trBottomHeight : trHeight"
              :data="facetArrGroup[index]"
              :scale="scale"
              :padding="index === facetLen ? facetPaddingFinal: facetPadding">
              <v-tooltip />
              <v-coord type="rect" direction="BL" />
              <v-legend v-if="index === facetLen" :offsetY="facetPaddingFinal[2] - 40" :clickable="false"/>
              <v-axis :dataKey="xAxisName" :label="xLabel" />
              <v-axis :dataKey="yAxisName1" :title="index === facetLen ? '': yAxisTitle1" :label="yAxisLabel"/>
              <v-axis :dataKey="yAxisName2" :title="index === facetLen ? '': yAxisTitle2" :label="yAxisLabel"/>
              <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust"/>
              <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" />
            </v-chart>
          </td>
        </tr>
      </table>
    </template>
    <template v-else>
      <v-tooltip />
      <v-legend :offsetY="padding[2]-10-46+15"/><!-- 标签长度减去图例本来位置-46，加上偏移量15-->
      <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
      <v-axis :dataKey="yAxisName1" :title="yAxisTitle1" :label="yAxisLabel"/>
      <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" />
      <v-axis :dataKey="yAxisName2" :title="yAxisTitle2" :label="yAxisLabel"/>
    </template>
    <v-axis :dataKey="xAxisName" :label="label" position="bottom"/>
    <v-guide
      v-if="reportData['predictStart']"
      type="region"
      :start="[reportData['predictStart'], 'min']"
      :end="[reportData['predictEnd'], 'max']"/>
  </v-chart>
</template>
<script>
// https://viserjs.gitee.io/demo.html
import { simplifyNumber } from '@/utils/numberUtil'
import _, { debounce } from 'lodash'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
const DataSet = require('@antv/data-set')
// const colors = ['#1890FF', '#73C9E6', '#13C2C2', '#6CD9B3', '#2FC25B', '#9DD96C', '#FACC14', '#E6965C']
/**
 * 只支持2种单位，1~2个纬度
 */
export default {
  name: 'LineColumnChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      ready: false,
      canvasHeight: 300,
      dataSource: [],
      xAxisName: '',
      xAxisLen: 0,
      yAxisName1: '',
      yAxisName2: '',
      yAxisTitle1: '',
      yAxisTitle2: '',
      seriesName1: '',
      seriesName2: '',
      facetField: '',
      facetLen: 0,
      xAxisTags: [],
      morespace: 40,
      adjust: [{
        type: 'dodge',
        marginRatio: 1 / 32
      }],
      padding: [20, 30, 0, 65],
      facetArray: [],
      facetPadding: [15, 65, 10, 65],
      facetPaddingFinal: [-60, 65, 44, 65],
      facetArrGroup: [],
      trHeight: 100,
      maxXtagLength: 0,
      trBottomHeight: 30,
      axiasTitleLen: 0
    }
  },
  computed: {
    scale () {
      return [{
        dataKey: this.xAxisName,
        type: 'cat',
        sync: false,
        tickCount: this.maxXtagLength > 8 ? (this.xAxisLen > 6 ? 6 : this.xAxisLen) : (this.xAxisLen > 12 ? 12 : this.xAxisLen)
      }, {
        dataKey: this.yAxisName1,
        tickCount: 5,
        max: Math.max.apply(Math, this.dataSource.map(x => x[this.yAxisName1])),
        min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName1])) < 0 ? Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName1])) : 0,
        sync: true
      },
      {
        dataKey: this.yAxisName2,
        tickCount: 5,
        max: Math.max.apply(Math, this.dataSource.map(x => x[this.yAxisName2])),
        min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) < 0 ? Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) : 0,
        sync: true
      }]
    },
    yAxisLabel() {
      return {
        formatter(val) {
          return simplifyNumber(val)
        },
        textStyle: {
          fontSize: '0.75em',
          fill: '#888888'
        }
      }
    },
    xLabel () {
      return {
        // autoRotate: false,
        rotate: this.maxXtagLength > 12 ? 70 : 45,
        offset: 30,
        textStyle: {
          fontSize: '0.75em',
          textAlign: 'start'
        }
      }
    },
    label () {
      return {
        autoRotate: false,
        rotate: this.maxXtagLength > 6 ? 70 : 45,
        offset: 10,
        textStyle: {
          fontSize: '0.75em',
          textAlign: 'start'
        }
      }
    }
  },
  mounted() {
    const _unit1 = this.reportData.units[0]['unit']
    const _unitEnclosed1 = _unit1 ? `（${_unit1}）` : ''
    const _metrics1 = this.reportData.units[0]['metrics']
    const _unit2 = this.reportData.units[1]['unit']
    const _metrics2 = this.reportData.units[1]['metrics']
    const _unitEnclosed2 = _unit2 ? `（${_unit2}）` : ''
    this.xAxisName = this.xAxis || this.reportData['dimensions'][0]
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    this.morespace = this.reportData.series.length < 20 ? 45 : (this.reportData.series.length < 40 ? 105 : (this.reportData.series.length < 60 ? 155 : 200))
    const dv = new DataSet.View().source(this.reportData.dataItems)
    dv.transform({
      type: 'fold',
      fields: _metrics1,
      key: 'metricsName1',
      value: 'metricsValue1'
    })
    dv.transform({
      type: 'fold',
      fields: _metrics2,
      key: 'metricsName2',
      value: 'metricsValue2'
    })
    this.seriesName1 = 'metricsName1'
    this.seriesName2 = 'metricsName2'
    this.yAxisName1 = 'metricsValue1'
    this.yAxisName2 = 'metricsValue2'
    // this.dataSource = dv.rows
    this.dataSource = dv.rows.map(item => {
      if (isNaN(item[this.yAxisName1]) || typeof item[this.yAxisName1] !== 'number') item[this.yAxisName1] = 0
      if (isNaN(item[this.yAxisName2]) || typeof item[this.yAxisName2] !== 'number') item[this.yAxisName2] = 0
      return item
    })
    this.yAxisTitle1 = {
      text: '【柱】' + _metrics1.join('、') + _unitEnclosed1,
      textStyle: {
        textAlign: 'center'
      }
    }
    this.yAxisTitle2 = {
      text: '【线】' + _metrics2.join('、') + _unitEnclosed2,
      textStyle: {
        textAlign: 'center'
      }
    }
    this.xAxisLen = _.uniqBy(this.dataSource, this.xAxisName).length

    this.axiasTitleLen = Math.max(getStrFullLength(this.yAxisTitle1.text), getStrFullLength(this.yAxisTitle2.text))
    if (this.axiasTitleLen > 20) {
      this.trHeight = 6.5 * this.axiasTitleLen
    }
    if (this.reportData['dimensions'].length === 2) {
      this.facetField = this.reportData['dimensions'].find(item => item !== this.xAxisName)
      if (this.facetField) {
        this.facetArray = _.uniqBy(this.dataSource, this.facetField).map(item => item[this.facetField])
        this.facetLen = this.facetArray.length
        this.facetArray.push(this.facetArray[0]) // 增加最后一行显示坐标轴
        this.$nextTick(() => {
          console.log('this.ref', this.$refs)
          const facetTitleArr = this.$refs['seriesTitle']
          const facetTitleHeightArr = facetTitleArr.map(item => item.offsetHeight)
          const _height = facetTitleHeightArr.reduce(function(prev, cur, index, array) {
            return prev + cur
          }) + 10 // 表格开头有10px
          // console.log('facetTitleHeightArr', facetTitleHeightArr)
          if (_height > this.height) {
            this.canvasHeight = _height
          } else {
            this.canvasHeight = this.height - 28 // 减去tooltip所占空间，手机端display为none，但pc端没有为none
            this.trHeight = Math.max(this.trHeight, (this.canvasHeight - facetTitleHeightArr[facetTitleHeightArr.length - 1]) / this.facetLen - 7 * this.facetLen - 5) // 减去每行比图表多出的空间,判断是否因为title过长需要加长显示
          }
        })

        // this.$nextTick(() => {
        //   console.log('this.ref', this.$refs)
        //   const firstTrArr = this.$refs['firstTr']
        //   const lastTrArr = this.$refs['lastTr']
        //   console.log('this.firstTrArr', firstTrArr)
        //   const firstTrHeightArr = firstTrArr.map(item => item.offsetHeight)
        //   const lastTrHeight = lastTrArr.offsetHeight
        //   const _height = firstTrHeightArr.reduce(function(prev, cur, index, array) {
        //     return prev + cur
        //   }) + lastTrHeight + 10 // 表格开头有10px
        //   // console.log('facetTitleHeightArr', facetTitleHeightArr)
        //   console.log('_height', _height)
        //   console.log('lastTrHeight', lastTrHeight)
        //   console.log('firstTrHeightArr', firstTrHeightArr)
        //   if (_height > this.height) {
        //     this.canvasHeight = _height
        //   } else {
        //     this.canvasHeight = this.height - 28 // 减去tooltip所占空间，手机端display为none，但pc端没有为none
        //     this.trHeight = (this.canvasHeight - lastTrHeight) / this.facetLen - 7 * this.facetLen - 5 // 减去每行比图表多出的空间

        //     // console.log('this.height', firstTrHeightArr)
        //     console.log('this.height', this.height)
        //     console.log('this.trHeight', this.trHeight)
        //   }
        // })

        this.getFacetDataArr()
      } else {
        this.canvasHeight = this.height
      }
    } else {
      this.canvasHeight = this.height
    }
    this.ready = true
    this.chartRenderedDelay()
    this.getPaddingBottom()
    this.getPaddingRight()
  },
  methods: {
    getFacetDataArr () {
      this.facetArrGroup.splice(0)
      this.facetArray.forEach((facetItem, index) => { // 得到子图数据数组 arrGroup
        this.facetArrGroup.push(this.dataSource.filter(item => item[this.facetField] === facetItem))
      })
      console.log('facetArrGroup', this.facetArrGroup)
      const sortXTagesArr = this.xAxisTags.sort()
      console.log('sortXTagesArr', sortXTagesArr)
      this.facetArrGroup = this.facetArrGroup.map(arr => {
        const newArr = []
        console.log('arr', arr)
        sortXTagesArr.forEach(xTag => {
          console.log('xTag', xTag)
          newArr.push(...arr.filter(item => item[this.xAxisName] === xTag)) // 指标和X都相等的项存在
        })
        console.log('newArr', newArr)
        return newArr
      })
    },
    getPaddingBottom (data) {
      this.maxXtagLength = 0
      this.xAxisTags.forEach((item) => {
        const len = getStrFullLength(item)
        if (len > this.maxXtagLength) {
          this.maxXtagLength = len
        }
      })
      const wordWidth = this.maxXtagLength > 12 ? 5.5 : 8 // 每个字节5.5长度，maxLength为字节长度
      this.trBottomHeight = this.maxXtagLength * wordWidth // 每个字11
      this.facetPaddingFinal[2] = 20 + this.trBottomHeight
      if (this.maxXtagLength > 16) {
        this.padding[2] = 13 + this.maxXtagLength / 2 / 2 * 15
      } else {
        this.padding[2] = -16 + this.maxXtagLength * wordWidth// 每个字11
      }
      if (this.reportData.metrics.length > 1) this.padding[2] += 25// 加上图例的高度
      // this.padding[2] = 11 + this.maxXtagLength * 9 // 单个图时，每个字节9
      // let maxLength = 0
      // this.xAxisTags.map((item) => {
      //   if (getStrFullLength(item) > maxLength) {
      //     maxLength = getStrFullLength(item)
      //   }
      // })
      // // this.padding[2] = 14 + maxLength / 2 * 8 + this.morespace // 每个字8
    },
    getPaddingRight (data) {
      const len = this.xAxisTags.length
      let i = len - 2
      let tag = 1
      let maxLength = getStrFullLength(this.xAxisTags[len - 1])
      const wordWidth = this.maxXtagLength > 12 ? 2.5 : 6 // 每个字节2.5宽度，maxLength为字节长度
      const wordNum = this.maxXtagLength > 12 ? 5 : 2
      while (i >= 0) {
        // 5表示当前一个比后一个多5个字以上时，才会导致前一个标签为到右边最远的标签
        // 将离右边最近的标签与其之前的标签对比，得到往右延伸更远的标签
        if (maxLength + wordNum * tag < getStrFullLength(this.xAxisTags[i])) {
          maxLength = getStrFullLength(this.xAxisTags[i])
          tag = 1
        }
        i--
        tag++
      }
      if (this.facetField) {
        this.facetPaddingFinal[1] = maxLength * wordWidth - 6 // 减去为0时已经显示的部分
        this.facetPadding[1] = maxLength * wordWidth - 6
      } else {
        if (this.maxXtagLength > 8) {
          if (this.reportData.units[1]) {
            this.padding[1] = 65
          } else {
            this.padding[1] = 30
          }
        } else {
          if (this.reportData.units[1]) {
            this.padding[1] = Math.max(maxLength * wordWidth - 44, 65)// 减去为0时已经显示的部分
          } else {
            this.padding[1] = Math.max(maxLength * wordWidth - 17, 30)
          }
        }
      }
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.line-column-chart {
  position: relative;
  .facet-table {
    width: 100%;
    position: absolute;
    top: 10px;
    border: none;
    border-color: #eeeeee;
    z-index: 2;
    border-spacing: 0;
    // border-left: none;
    // border-right: none;
    // td {
    //   height: 238px;
    // }
    tr:first-child {
      td {
        border-top-style: solid !important;
        border-top-color: #eeeeee !important;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
    tr {
      height: 100%;
      td:first-child {
        text-align: center;
        border-left: none;
        border-top: none;
        border-width: 1px;
        width: 20%;
        font-size: 0.75em;
      }
      td:last-child {
        border-right: none;
        border-left: none;
        border-top: none;
        font-size: 0.75em;
        border-width: 1px;
      }
    }
  }
}
</style>
