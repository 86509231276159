<template>
  <div class="dashboard-text" :class="contentClass">
    <div class="dashboard-text-inner" :style="contentStyle">
      {{ model['content'] }}
    </div>
  </div>
</template>

<script>
import PropertyHelp from '../data/PropertyHelp'

export default {
  name: 'DashboardText',
  components: {},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    dashboardModel: {
      type: Object,
      default: () => {
        return {
          dataRegionId: ''
        }
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    contentClass() {
      const { contentStyle, content } = this.model
      return {
        bold: contentStyle['fontWeight'] === 'bold',
        left: contentStyle['horizontal'] === 'left',
        center: contentStyle['horizontal'] === 'center',
        right: contentStyle['horizontal'] === 'right',
        top: contentStyle['vertical'] === 'top',
        middle: contentStyle['vertical'] === 'middle',
        bottom: contentStyle['vertical'] === 'bottom',
        placeholder: !content
      }
    },
    contentStyle() {
      const fontStyle = PropertyHelp.getFontStyle(this.model.contentStyle)
      const paddingStyle = PropertyHelp.getPaddingStyle(this.model)
      return Object.assign({}, fontStyle, paddingStyle)
    }
  },
  methods: {},
  mounted() {
  }
}
</script>
